import { Alert, AlertProps, Snackbar, Stack, Typography } from '@mui/material';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Divider, ReportForm } from '../components';
import { useAddIncidentDocuments, useReportIncident } from '../mutations';
import { useReportIncidentSlice } from '../store';
import { ReportForm as ReportFormType } from '../types';

export function ReportPage() {
  const [alert, setAlert] = useState<{
    open: boolean;
    message: string;
    severity: AlertProps['severity'];
  }>();
  const { clearIncidentLocation } = useReportIncidentSlice();

  const { control, handleSubmit, reset, ...rest } = useForm<ReportFormType>({
    defaultValues: {
      accepted_terms: false,
      lat: '0',
      lng: '0',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      have_bees_died: false,
      hives_with_dead_bees: 0,
      have_bees_left_the_hive: false,
      hives_with_bees_outside: 0,
      changes_in_bee_behaviour: '',
      phytosanitary_treatments: '',
      vandalism_choices: '',
      is_unknown: false,
      was_alert_announced: false,
      product_alert: '',
      were_bees_removed: false,
      were_hives_moved: false,
      other_actions: '',
      announcement_made_to: [],
      details: '',
      is_ambulance_needed: false,
    },
    mode: 'onChange',
  });

  const { mutate: reportIncident, isLoading } = useReportIncident();
  const { mutate: addIncidentDocuments } = useAddIncidentDocuments();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback<SubmitHandler<ReportFormType>>(
    async (data) => {
      const token = await executeRecaptcha?.();
      if (token) {
        reportIncident(
          {
            ...omit(data, ['accepted_terms', 'incident_location', 'county']),
            phytosanitary_treatments: data.phytosanitary_treatments
              ? [data.phytosanitary_treatments]
              : [],
            vandalism_choices: data.vandalism_choices
              ? [data.vandalism_choices]
              : [],
            lat: data.incident_location?.lat ?? '',
            lng: data.incident_location?.lng ?? '',
            documents: [],
          },
          {
            onSuccess: async ({ pk: alertPk }) => {
              await Promise.all(
                data.documents?.map((document) =>
                  addIncidentDocuments({
                    alert: alertPk,
                    file: document,
                  }),
                ) ?? [],
              );
              reset();
              clearIncidentLocation();
              setAlert({
                open: true,
                message: 'Incidentul a fost raportat cu succes',
                severity: 'success',
              });
            },
            onError: () =>
              setAlert({
                open: true,
                message: 'Am întâmpinat o problemă. Încearcă din nou',
                severity: 'error',
              }),
          },
        );
      }
    },
    [
      addIncidentDocuments,
      clearIncidentLocation,
      executeRecaptcha,
      reportIncident,
      reset,
    ],
  );

  return (
    <Stack gap={4} py={5}>
      <Typography
        color='primary'
        fontSize={{ xs: 40, md: 52 }}
        textAlign='center'
        variant='h2'
      >
        Raportează un incident
      </Typography>
      <Typography textAlign='center' variant='h6'>
        În fiecare an există apicultori care se confruntă cu situații de
        intoxicare a albinelor. În cele mai multe cazuri este vorba de otrăvirea
        albinelor ca urmare a unor tratamente fito-sanitare, uneori cu urmări
        dezastruoase pentru apicultor. În foarte puține asemenea cazuri
        apicultorului păgubit i se face dreptate iar compensarea firească a
        pierderilor suferite de multe ori nu are loc pentru că apicultorul nu
        știe cum trebuie să procedeze. Punând răul înainte, este bine să fiți
        pregătit și bine informat pentru o asemenea eventualitate nedorită.
      </Typography>

      <Typography textAlign='center' variant='h6'>
        Dacă doriți sprijin din partea noastră vă îndemnăm să completați
        formularul de mai jos iar noi vom analiza cum vă putem ajuta cel mai
        bine.
      </Typography>

      <Divider />

      <FormProvider
        {...rest}
        control={control}
        handleSubmit={handleSubmit}
        reset={reset}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ReportForm isLoading={isLoading} />
        </form>
      </FormProvider>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        open={alert?.open}
        onClose={() => setAlert(undefined)}
      >
        <Alert variant='filled' severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
