import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Fab,
  Fade,
  Grid,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { AlertNoticeItem, Divider } from '../components';
import { COUNTIES } from '../constants';
import {
  DEFAULT_ALERTS_STROKE_COLOR,
  HIGH_ALERTS_FILL_COLOR,
  LOW_ALERTS_FILL_COLOR,
  MEDIUM_ALERTS_FILL_COLOR,
  useInitializeAlertsMap,
} from '../hooks';
import { useWarningNoticesQuery } from '../queries';
import { Counties } from '../types';

const MapContainer = styled(Box)(({ theme }) => ({
  '& > div': {
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
  },
}));

export function AlertsPage() {
  const [selectedCounty, setSelectedCounty] = useState<Counties>();
  const gridRef = useRef<HTMLDivElement>(null);

  const onCountyClick = useCallback((county: Counties) => {
    setSelectedCounty(county);
    gridRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const { data: warningNotices } = useWarningNoticesQuery();

  const warningNoticesPerCounty = useMemo(
    () =>
      warningNotices?.reduce((acc, { county }) => {
        acc[county] = acc[county] ? acc[county] + 1 : 1;
        return acc;
      }, {} as Record<Counties, number>),
    [warningNotices],
  );

  const { mapRef } = useInitializeAlertsMap(
    warningNoticesPerCounty,
    onCountyClick,
  );

  const filteredWarningNotices = useMemo(
    () =>
      warningNotices?.filter(({ county }) =>
        !selectedCounty ? true : selectedCounty === county || county === 'RO',
      ) ?? [],
    [selectedCounty, warningNotices],
  );

  return (
    <Stack gap={4} py={8}>
      <Typography
        color='primary'
        fontSize={{ xs: 36, md: 48 }}
        textAlign='center'
        variant='h2'
      >
        Alerte
      </Typography>

      <Divider />

      <Box>
        <Typography textAlign='center'>
          Dă click pe un județ pentru a afla mai multe detalii despre
          avertizarea emisă de sucursala fitosanitară județeană
        </Typography>

        <Stack
          alignItems='center'
          direction='row'
          gap={2}
          justifyContent='center'
        >
          <Typography fontWeight='bold' variant='body2'>
            Legendă:
          </Typography>
          <Box
            bgcolor={LOW_ALERTS_FILL_COLOR}
            border={1}
            borderColor={DEFAULT_ALERTS_STROKE_COLOR}
            borderRadius={1}
            height={20}
            width={60}
          />
          <Typography variant='body2'>1 - 5 alerte</Typography>
          <Box
            bgcolor={MEDIUM_ALERTS_FILL_COLOR}
            border={1}
            borderColor={DEFAULT_ALERTS_STROKE_COLOR}
            borderRadius={1}
            height={20}
            width={60}
          />
          <Typography variant='body2'>6 - 10 alerte</Typography>
          <Box
            bgcolor={HIGH_ALERTS_FILL_COLOR}
            border={1}
            borderColor={DEFAULT_ALERTS_STROKE_COLOR}
            borderRadius={1}
            height={20}
            width={60}
          />
          <Typography variant='body2'>&#8250; 10 alerte</Typography>
        </Stack>
      </Box>

      <MapContainer flexGrow={1} height={600} ref={mapRef} />

      <Grid columnSpacing={4} container ref={gridRef} rowSpacing={6} mt={4}>
        {selectedCounty && (
          <Grid item xs={12}>
            <Typography variant='h5'>
              {!filteredWarningNotices.length
                ? 'Nu există buletine de avertizare pentru județul'
                : 'Buletine de avertizare pentru județul'}{' '}
              <strong>
                {COUNTIES.find(({ id }) => id === selectedCounty)?.label}
              </strong>
              {' | '}
              <Button
                component='span'
                onClick={() => setSelectedCounty(undefined)}
                size='small'
              >
                Afișează toate buletinele
              </Button>
            </Typography>
          </Grid>
        )}
        {filteredWarningNotices.map((alert) => (
          <Grid item key={alert.pk} xs={12} sm={4}>
            <AlertNoticeItem key={alert.pk} alert={alert} />
          </Grid>
        ))}
      </Grid>

      <Fade in={trigger}>
        <Box
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          role='presentation'
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <Fab color='primary' size='small'>
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
    </Stack>
  );
}
