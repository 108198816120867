import H from '@here/maps-api-for-javascript';
import { alpha } from '@mui/material';
import { useEffect, useRef } from 'react';
import { COUNTIES_GEOJSON_URL, DEFAULT_MAP_OPTIONS } from '../constants';

export const HIGH_ALERTS_FILL_COLOR = alpha('#FCA5A5', 0.4);
// const HIGH_ALERTS_STROKE_COLOR = '#991B1B';
export const MEDIUM_ALERTS_FILL_COLOR = alpha('#FEF08A', 0.4);
// const MEDIUM_ALERTS_STROKE_COLOR = '#713F12';
export const LOW_ALERTS_FILL_COLOR = alpha('#E0F2FE', 1);
// const LOW_ALERTS_STROKE_COLOR = '#0C4A6E';
const DEFAULT_ALERTS_FILL_COLOR = alpha('#FFF6D5', 0.4);
export const DEFAULT_ALERTS_STROKE_COLOR = '#8F8E8B';

const parseCountiesGeoJson = (
  map: H.Map,
  countyAlertsCount: any,
  onCountyClick: any,
) => {
  let countyMarker: H.map.DomMarker;
  const reader = new H.data.geojson.Reader(COUNTIES_GEOJSON_URL, {
    style: (countyObject) => {
      if (countyObject instanceof H.map.Polygon) {
        const county = countyObject.getData().properties.mnemonic;

        const alertsCount = countyAlertsCount[county] ?? 0;

        const style = {
          fillColor: DEFAULT_ALERTS_FILL_COLOR,
          strokeColor: DEFAULT_ALERTS_STROKE_COLOR,
          lineWidth: 3,
        };

        if (alertsCount > 10) {
          style.fillColor = HIGH_ALERTS_FILL_COLOR;
          // style.strokeColor = HIGH_ALERTS_STROKE_COLOR;
        } else if (alertsCount >= 5) {
          style.fillColor = MEDIUM_ALERTS_FILL_COLOR;
          // style.strokeColor = MEDIUM_ALERTS_STROKE_COLOR;
        } else if (alertsCount > 0) {
          style.fillColor = LOW_ALERTS_FILL_COLOR;
          // style.strokeColor = LOW_ALERTS_STROKE_COLOR;
        }

        const label = document.createElement('div');
        const innerLabel = document.createElement('div');
        innerLabel.innerHTML = county;
        label.appendChild(innerLabel);
        label.style.fontWeight = 'bold';
        label.style.cursor = 'pointer';
        const center = countyObject.getGeometry().getBoundingBox()?.getCenter();
        const movedCenter = center?.walk(90, 10000);
        const domIcon = new H.map.DomIcon(label, {
          onAttach(clonedElement) {
            const clonedContent = clonedElement.getElementsByTagName('div')[0];
            const y = county === 'IF' ? 20 : 12;
            clonedContent.style.transform = `translate(-15px, -${y}px)`;
          },
        });
        if (movedCenter) {
          countyMarker = new H.map.DomMarker(movedCenter, {
            icon: domIcon,
            data: county,
          });
          if (countyMarker) {
            countyMarker.addEventListener('tap', () => {
              onCountyClick(county);
            });
            map.addObject(countyMarker);
          }
        }

        countyObject.setStyle(style);
        countyObject.addEventListener('tap', () => {
          onCountyClick(county);
        });
      }
    },
  });

  reader.parse();
  map.addLayer(reader.getLayer());
};

export function useInitializeAlertsMap(
  countyAlertsCount: any,
  onCountyClick: any,
) {
  const mapRef = useRef<HTMLElement>();

  useEffect(() => {
    if (!process.env.REACT_APP_HERE_MAPS_API_KEY || !mapRef.current) {
      return undefined;
    }

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });

    const layers = platform.createDefaultLayers();

    const hMap = new H.Map(mapRef.current, layers.vector.normal.map, {
      ...DEFAULT_MAP_OPTIONS,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const events = new H.mapevents.MapEvents(hMap);

    parseCountiesGeoJson(hMap, countyAlertsCount, onCountyClick);

    return () => {
      hMap.dispose();
    };
  }, [countyAlertsCount, onCountyClick]);

  return { mapRef };
}
