import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {
  Alert,
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, SyntheticEvent, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { AmbulanceLink } from './AmbulanceLink';
import { Dropzone } from './Dropzone';
import { ReportIncidentMap } from './ReportIncidentMap';
import { SectionNumber } from './SectionNumber';
import { SectionTitle } from './SectionTitle';
import { useAddIncidentMarkerAtLocation } from '../hooks';
import {
  useHoneyFieldNearestAddressQuery,
  useMapAutocompleteResultsQuery,
  usePhytosanitaryChoicesQuery,
  useVandalismChoicesQuery,
} from '../queries';
import { useReportIncidentSlice } from '../store';
import {
  MapDiscoverResult,
  ReportForm as ReportFormType,
  Routes,
} from '../types';
import { isMapDiscoverResult } from '../utils';

const DEFAULT_OPTION: Pick<MapDiscoverResult, 'title'> = {
  title: 'Folosește locația curentă',
};

type ReportFormProps = {
  isLoading: boolean;
};

export function ReportForm({ isLoading }: ReportFormProps) {
  const { typography } = useTheme();
  const {
    control,
    setValue: setFieldValue,
    getValues,
  } = useFormContext<ReportFormType>();

  const [searchAddress, setSearchAddress] = useState('');

  const [value, setValue] = useState<
    { title: string } | MapDiscoverResult | null
  >(null);

  const { incidentLocation, setIncidentLocation } = useReportIncidentSlice();
  const addIncidentMarkerAtLocation = useAddIncidentMarkerAtLocation();

  const { data: autosuggestResults, isFetching } =
    useMapAutocompleteResultsQuery(searchAddress, {
      enabled: searchAddress !== DEFAULT_OPTION.title,
    });

  const { data, isFetching: isLoadingLocation } =
    useHoneyFieldNearestAddressQuery(incidentLocation ?? { lat: 0, lng: 0 }, {
      enabled: !!incidentLocation?.lat && !!incidentLocation?.lng,
      onSuccess: ({ address }) => {
        if (incidentLocation) {
          setFieldValue(
            'incident_location.lat',
            incidentLocation.lat.toFixed(6).toString(),
          );
          setFieldValue(
            'incident_location.lng',
            incidentLocation.lng.toFixed(6).toString(),
          );
          setFieldValue('county', address.county);
        }
      },
    });

  const { data: vandalismChoices } = useVandalismChoicesQuery();
  const { data: phytosanitaryChoices } = usePhytosanitaryChoicesQuery();

  const [openMessage, setOpenMessage] = useState(false);

  const onOptionChangeHandler = useCallback(
    (
      event: SyntheticEvent,
      newValue: MapDiscoverResult | Pick<MapDiscoverResult, 'title'> | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (reason === 'clear') {
        setValue(null);
        return;
      }

      setValue(newValue);
      if (isMapDiscoverResult(newValue)) {
        const { lat, lng } = newValue.position;
        const point = { lat, lng };
        addIncidentMarkerAtLocation(point);
        setIncidentLocation(point);
      } else {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            const { latitude: lat, longitude: lng } = coords;
            const point = { lat, lng };
            addIncidentMarkerAtLocation(point);
            setIncidentLocation(point);
          },
          () => setOpenMessage(true),
        );
      }
    },
    [addIncidentMarkerAtLocation, setIncidentLocation],
  );

  const [possibleCauses, setPossibleCauses] = useState(
    'phytosanitary_treatments',
  );

  const onChangeAnnouncedAuthorities = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedValues = getValues('announcement_made_to');
      if (selectedValues.includes(event.target.name)) {
        setFieldValue(
          'announcement_made_to',
          selectedValues.filter((v) => v !== event.target.value),
        );
      } else {
        setFieldValue('announcement_made_to', [
          ...selectedValues,
          event.target.value,
        ]);
      }
    },
    [getValues, setFieldValue],
  );

  return (
    <Grid columnSpacing={2} container rowSpacing={8}>
      <Grid item sm={1} xs={2}>
        <SectionNumber label='1' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle
            subtitle='Notă: Pentru a identifica locul unde s-a produs incidentul pe care îl raportezi avem nevoie de coordonatele GPS ale acestuia. Introdu mai jos localitatea unde s-a produs intoxicarea, identifică pe harta aeriană locul exact unde se aflau stupii la momentul intoxicării și dă click pe acel loc.'
            title='Despre locația stupinei*'
          />

          <Grid columnSpacing={6} container rowSpacing={3}>
            <Grid item sm={7} xs={12}>
              <Autocomplete
                loading={isFetching}
                disablePortal
                filterOptions={(x) => x}
                getOptionLabel={(option) => {
                  if (isMapDiscoverResult(option)) {
                    return option.address.label;
                  }
                  return option.title;
                }}
                onInputChange={(_, newInputValue) =>
                  setSearchAddress(newInputValue)
                }
                options={[DEFAULT_OPTION, ...(autosuggestResults?.items ?? [])]}
                onChange={onOptionChangeHandler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Unde ești acum?'
                    InputProps={{ ...params.InputProps, notched: false }}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={isMapDiscoverResult(option) ? option.id : option.title}
                  >
                    <Typography>
                      {isMapDiscoverResult(option)
                        ? option.address.label
                        : option.title}
                    </Typography>
                  </li>
                )}
                value={value}
              />
            </Grid>
            <Grid display={{ xs: 'none', sm: 'block' }} item sm={5} xs={0} />

            <Grid item xs={12}>
              <ReportIncidentMap />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='incident_location'
                render={({ field, fieldState }) => (
                  <>
                    <input ref={field.ref} name={field.name} hidden />
                    <Stack
                      bgcolor='#FAFAFA'
                      border={1}
                      borderColor='#EE403633'
                      borderRadius={1}
                      gap={2}
                      px={4}
                      py={6}
                      height={126}
                    >
                      {isLoadingLocation ? (
                        <CircularProgress />
                      ) : (
                        data && (
                          <Stack direction='row' gap={2}>
                            <LocationOnOutlinedIcon />
                            <Stack>
                              <Typography
                                fontWeight={typography.fontWeightBold}
                                variant='h6'
                              >
                                {data?.address.district ?? data?.address.city}
                              </Typography>
                              <Typography>{data?.address.county}</Typography>
                              <Typography variant='caption'>
                                {incidentLocation?.lat.toFixed(4)}
                                {', '}
                                {incidentLocation?.lng.toFixed(4)}
                              </Typography>
                            </Stack>
                          </Stack>
                        )
                      )}
                    </Stack>
                    <FormHelperText error>
                      {fieldState.error?.message || ' '}
                    </FormHelperText>
                  </>
                )}
                rules={{
                  validate: (field) =>
                    !field.lat ? 'Alegeți o locație' : true,
                  required: {
                    value: true,
                    message: 'Alegeți o locație',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item sm={1} xs={2}>
        <SectionNumber label='2' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle title='Despre tine' />

          <Grid columnSpacing={6} container rowSpacing={2}>
            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='last_name'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Nume*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message: 'Numele poate avea maximum 100 de caractere',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='first_name'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Prenume*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message: 'Prenumele poate avea maximum 100 de caractere',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='email'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Adresa de e-mail*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 100,
                    message:
                      'Adresa de email poate avea maximum 100 de caractere',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Adresa de email nu este validă',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Controller
                control={control}
                name='phone'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Telefon*:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      type='tel'
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  pattern: {
                    value: /\d{10}/,
                    message: 'Numărul de telefon nu este valid',
                  },
                  required: {
                    value: true,
                    message: 'Acest câmp este obligatoriu',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name='identification_code'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Codul ANZ de identificare a stupinei:
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item sm={1} xs={2}>
        <SectionNumber label='3' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle title='Despre incident' />

          <Grid columnSpacing={6} container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4'>Observații clinice</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='have_bees_died'
                render={({ field: { onChange } }) => (
                  <FormControl>
                    <FormLabel id='mortality'>Mortalitate</FormLabel>
                    <RadioGroup
                      defaultValue={false}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='Nu'
                      />
                      <FormControlLabel value control={<Radio />} label='Da' />
                    </RadioGroup>
                  </FormControl>
                )}
              />

              <Box>
                <Controller
                  control={control}
                  name='hives_with_dead_bees'
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <InputLabel htmlFor={field.name} variant='outlined'>
                        Număr de stupi afectați:
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(fieldState.error)}
                        id={field.name}
                        {...field}
                      />
                      <FormHelperText error={Boolean(fieldState.error)}>
                        {fieldState.error?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='have_bees_left_the_hive'
                render={({ field: { onChange } }) => (
                  <FormControl>
                    <FormLabel>Depopulare</FormLabel>
                    <RadioGroup
                      defaultValue={false}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='Nu'
                      />
                      <FormControlLabel value control={<Radio />} label='Da' />
                    </RadioGroup>
                  </FormControl>
                )}
              />
              <Box>
                <Controller
                  control={control}
                  name='hives_with_bees_outside'
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <InputLabel htmlFor={field.name} variant='outlined'>
                        Număr de stupi afectați:
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(fieldState.error)}
                        id={field.name}
                        {...field}
                      />
                      <FormHelperText error={Boolean(fieldState.error)}>
                        {fieldState.error?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='changes_in_bee_behaviour'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Schimbare de comportament (descrieți, de exemplu
                      agresivitate, letargie, etc.):
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      placeholder='Exemplu agresivitate, letargie, etc.'
                      multiline
                      rows={4}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 250,
                    message: 'Descrierea poate avea maximum 250 de caractere',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h4'>Cauze probabile</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  defaultValue='phytosanitary_treatments'
                  value={possibleCauses}
                  onChange={(event) => {
                    if (event.target.value === 'is_unknown') {
                      setFieldValue('is_unknown', true);
                    } else {
                      setFieldValue('is_unknown', false);
                    }
                    setPossibleCauses(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value='phytosanitary_treatments'
                    control={<Radio />}
                    label='Tratamente fito-sanitare'
                  />
                  <Controller
                    control={control}
                    name='phytosanitary_treatments'
                    render={({ field }) => (
                      <FormControl>
                        <Select
                          {...field}
                          id={field.name}
                          disabled={
                            possibleCauses !== 'phytosanitary_treatments' ||
                            !phytosanitaryChoices?.length
                          }
                        >
                          {phytosanitaryChoices?.map((choice) => (
                            <MenuItem key={choice.pk} value={choice.pk}>
                              {choice.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <FormControlLabel
                    value='vandalism_choices'
                    control={<Radio />}
                    label='Vandalism/furt'
                  />
                  <Controller
                    control={control}
                    name='vandalism_choices'
                    render={({ field }) => (
                      <FormControl>
                        <Select
                          {...field}
                          id={field.name}
                          disabled={
                            possibleCauses !== 'vandalism_choices' ||
                            !vandalismChoices?.length
                          }
                        >
                          {vandalismChoices?.map((choice) => (
                            <MenuItem key={choice.pk} value={choice.pk}>
                              {choice.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <FormControlLabel
                    value='is_unknown'
                    control={<Radio />}
                    label='Cauze necunoscute'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h4'>Împrejurările incidentului</Typography>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='was_alert_announced'
                render={({ field: { onChange } }) => (
                  <FormControl>
                    <FormLabel>
                      Ați primit alertă de efectuare a tratamentelor
                      fitosanitare de la primărie sau fermier?
                    </FormLabel>
                    <RadioGroup
                      defaultValue={false}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='Nu'
                      />
                      <FormControlLabel value control={<Radio />} label='Da' />
                    </RadioGroup>
                  </FormControl>
                )}
              />
              <Box>
                <Controller
                  control={control}
                  name='product_alert'
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor={field.name} variant='outlined'>
                        Pentru stropire cu produsul numit:
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(fieldState.error)}
                        id={field.name}
                        {...field}
                      />
                      <FormHelperText error={Boolean(fieldState.error)}>
                        {fieldState.error?.message ?? ' '}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='were_bees_removed'
                render={() => (
                  <FormControl>
                    <FormLabel>
                      Ați îndepărtat albinele moarte de pe oglinda stupului sau
                      de pe fundul stupului ?
                    </FormLabel>
                    <RadioGroup defaultValue='no'>
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='Nu'
                      />
                      <FormControlLabel value control={<Radio />} label='Da' />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='were_hives_moved'
                render={({ field: { onChange } }) => (
                  <FormControl>
                    <FormLabel>
                      Ați mutat stupii după producerea incidentului?
                    </FormLabel>
                    <RadioGroup
                      defaultValue={false}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='Nu'
                      />
                      <FormControlLabel value control={<Radio />} label='Da' />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='other_actions'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor={field.name} variant='outlined'>
                      Ați întreprins alte acțiuni care au modificat starea de
                      fapte de la momentul incidentului?
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      multiline
                      rows={4}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='announcement_made_to'
                render={() => (
                  <FormControl>
                    <FormLabel>Ați anunțat incidentul la:</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        value='VE'
                        control={
                          <Checkbox
                            onChange={onChangeAnnouncedAuthorities}
                            name='VE'
                          />
                        }
                        label='Medicul veterinar'
                      />
                      <FormControlLabel
                        value='MA'
                        control={
                          <Checkbox
                            onChange={onChangeAnnouncedAuthorities}
                            name='MA'
                          />
                        }
                        label='Primarie'
                      />
                      <FormControlLabel
                        value='PO'
                        control={
                          <Checkbox
                            onChange={onChangeAnnouncedAuthorities}
                            name='PO'
                          />
                        }
                        label='Poliția locală'
                      />
                      <FormControlLabel
                        value='PA'
                        control={
                          <Checkbox
                            onChange={onChangeAnnouncedAuthorities}
                            name='PA'
                          />
                        }
                        label='Autoritatea fito-sanitară'
                      />
                    </FormGroup>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h4'>
                Alte observații sau constatări
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='details'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <OutlinedInput
                      error={Boolean(fieldState.error)}
                      id={field.name}
                      multiline
                      rows={4}
                      {...field}
                    />
                    <FormHelperText error={Boolean(fieldState.error)}>
                      {fieldState.error?.message ?? ' '}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{
                  maxLength: {
                    value: 1800,
                    message: 'Descrierea poate avea maximum 1800 de caractere',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h5' fontWeight={typography.fontWeightBold}>
                Dacă ai imagini sau documente care pot arăta impactul
                incidentului, adaugă-le aici
              </Typography>
              <Typography variant='body2'>
                Fișiere acceptate PNG, JPEG, .tif, .docx, .pdf maxim 10 MB
              </Typography>
              <Dropzone />
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item sm={1} xs={2}>
        <SectionNumber label='4' />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Stack gap={3}>
          <SectionTitle
            title={
              <Typography
                fontSize={{
                  xs: typography.pxToRem(28),
                  md: typography.pxToRem(32),
                }}
                fontWeight='bold'
                variant='h3'
              >
                Sunteți interesat să solicitați intervenția <AmbulanceLink />?
              </Typography>
            }
          />
        </Stack>
      </Grid>

      <Grid display={{ xs: 'none', sm: 'block' }} item sm={1} xs={0} />
      <Grid item sm={11} xs={12}>
        <Controller
          control={control}
          name='is_ambulance_needed'
          render={({ field }) => (
            <FormControl>
              <FormControlLabel
                control={<Checkbox checked={field.value} {...field} />}
                label={
                  <Typography fontWeight='bold' textAlign='justify'>
                    Da, solicit intervenția Ambulaței apicole
                  </Typography>
                }
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid display={{ xs: 'none', sm: 'block' }} item sm={1} xs={0} />
      <Grid item sm={11} xs={12}>
        <Stack>
          <Controller
            control={control}
            name='accepted_terms'
            render={({ field, fieldState }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox checked={field.value} {...field} />}
                  label={
                    // eslint-disable-next-line react/jsx-no-undef
                    <Typography textAlign='justify'>
                      Prin această bifă îți exprimi acordul ca datele furnizate
                      de tine prin acest formular să fie procesate exclusiv in
                      scopul de a încărca în platformă acest document și ca
                      echipa ROMAPIS să te contacteze doar în legătură cu
                      această submisie. Aici puteți găsi{' '}
                      <Typography
                        color='#198ECF'
                        component={Link}
                        target='_blank'
                        to={Routes.Terms}
                        sx={{ textDecoration: 'none' }}
                      >
                        regulamentul nostru cu privire la prelucrarea datelor cu
                        caracter personal
                      </Typography>
                      .
                    </Typography>
                  }
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: {
                value: true,
                message: 'Acest câmp este obligatoriu',
              },
            }}
          />
        </Stack>
      </Grid>

      <Grid display={{ xs: 'none', sm: 'block' }} item sm={10} xs={0} />
      <Grid item sm={2} xs={12} textAlign='right'>
        <Button
          fullWidth
          disabled={isLoading}
          type='submit'
          variant='contained'
          sx={{ minWidth: 88 }}
        >
          {!isLoading ? 'Trimite raportarea' : <CircularProgress size={24.5} />}
        </Button>
      </Grid>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        onClose={() => setOpenMessage(false)}
        open={openMessage}
      >
        <Alert severity='warning' sx={{ width: '100%' }}>
          Locația este blocată. Pentru a folosi această opțiune permite
          folosirea locației și încearcă din nou.
        </Alert>
      </Snackbar>
    </Grid>
  );
}
