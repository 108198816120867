import { Box, Typography, useTheme } from '@mui/material';
import hexagonSrc from '../assets/svg/hexagon.svg';

type SectionNumberProps = {
  label: string;
};
export function SectionNumber({ label }: SectionNumberProps) {
  const { typography } = useTheme();

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='center'
      position='relative'
      sx={{
        backgroundImage: `url(${hexagonSrc})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <Typography
        color='primary'
        fontSize={{ xs: typography.pxToRem(28), md: typography.pxToRem(44) }}
        fontWeight={typography.fontWeightBold}
      >
        {label}
      </Typography>
    </Box>
  );
}
