import { useCallback } from 'react';

export const useRemoveMapObjectsByKey = () =>
  useCallback((key: string, map?: H.Map) => {
    if (!map) return;

    const mapObjects = map.getObjects();
    const filteredObjects =
      mapObjects?.filter((object) => object.getData()?.key === key) ?? [];

    map.removeObjects(filteredObjects);
  }, []);
