import H from '@here/maps-api-for-javascript';
import { omit } from 'lodash';
import { StateCreator } from 'zustand';
import { ReportIncidentMapSlice } from './types';

export const createReportIncidentMapSlice: StateCreator<
  ReportIncidentMapSlice
> = (set) => ({
  disposeReportMap: () =>
    set((state) => omit(state, ['mapBehavior', 'map', 'ui']), true),
  setReportMap: (reportMap: H.Map) => set({ reportMap }),
});
