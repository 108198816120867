import { isDomMarker } from './typeGuards';

export const addDragMarkerHandlers = (
  map: H.Map,
  behavior: H.mapevents.Behavior,
  onDragEnd?: (point: H.geo.Point) => void,
) => {
  map.addEventListener(
    'dragstart',
    (event: H.mapevents.Event) => {
      const { target, currentPointer } = event;
      if (isDomMarker(target)) {
        const targetPosition = map.geoToScreen(
          target.getGeometry() as H.geo.Point,
        );
        if (targetPosition) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          target.offset = new H.math.Point(
            currentPointer.viewportX - targetPosition.x,
            currentPointer.viewportY - targetPosition.y,
          );
        }
        behavior.disable();
      }
    },
    false,
  );

  map.addEventListener(
    'dragend',
    (event: H.mapevents.Event) => {
      const { target, currentPointer } = event;
      if (isDomMarker(target)) {
        behavior.enable();
        const coord = map.screenToGeo(
          currentPointer.viewportX,
          currentPointer.viewportY,
        );

        if (coord) {
          onDragEnd?.(coord);
        }
      }
    },
    false,
  );

  map.addEventListener(
    'drag',
    (event: H.mapevents.Event) => {
      const { target, currentPointer } = event;

      if (isDomMarker(target)) {
        const newCoords = map.screenToGeo(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          currentPointer.viewportX - target.offset.x,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          currentPointer.viewportY - target.offset.y,
        );

        if (newCoords) {
          target.setGeometry(newCoords);
        }
      }
    },
    false,
  );
};
