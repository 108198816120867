import H from '@here/maps-api-for-javascript';
import { omit } from 'lodash';
import { StateCreator } from 'zustand';
import { IncidentsMapSlice } from './types';
import { AffectedHoneyField } from '../types';

export const createIncidentsMapSlice: StateCreator<IncidentsMapSlice> = (
  set,
) => ({
  clearSelectedMarker: () =>
    set((state) => omit(state, 'selectedMarker'), true),
  clearHiveData: () => set((state) => omit(state, 'hiveData'), true),
  setHiveData: (
    position: H.geo.IPoint,
    honeyFieldsInRange: AffectedHoneyField[],
  ) => set({ hiveData: { position, honeyFieldsInRange } }),
  setSelectedMarker: (pk: number) => set({ selectedMarker: pk }),
});
