import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BeeHiveDetailsPanel } from './BeeHiveDetailsPanel';
import { useIncidentsMap } from '../store';

export function IncidentsMapSidePanel() {
  const { breakpoints, palette, zIndex } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const { hiveData, clearHiveData, clearSelectedMarker } = useIncidentsMap();

  const onCloseDialog = () => {
    clearHiveData();
    clearSelectedMarker();
  };

  if (!hiveData) {
    return null;
  }

  if (isMobile) {
    return (
      <Dialog open={!!hiveData} fullWidth>
        <DialogContent sx={{ p: 0 }}>
          <BeeHiveDetailsPanel />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} variant='outlined'>
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box
      bgcolor={palette.background.default}
      bottom={0}
      left={0}
      position={{ xs: 'absolute', sm: 'relative' }}
      right={0}
      top={0}
      zIndex={{ xs: zIndex.appBar - 1, sm: 0 }}
      maxWidth={400}
      maxHeight={560}
    >
      {hiveData && <BeeHiveDetailsPanel showBoxShadow showCloseButton />}
    </Box>
  );
}
