// import EngineeringIcon from '@mui/icons-material/Engineering';
// import { Stack, Typography } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createBrowserRouter } from 'react-router-dom';
import {
  AboutPage,
  AmbulancePage,
  ContactPage,
  HomePage,
  InstallInstructionsPage,
  ReportPage,
  ReportsPage,
  Root,
  TermsPage,
} from '../pages';
import { Routes } from '../types';

// function Tbd({ title }: { title: string }) {
//   return (
//     <Stack
//       alignItems='center'
//       flexGrow={1}
//       fontSize={100}
//       height={1}
//       justifyContent='center'
//     >
//       <EngineeringIcon color='disabled' fontSize='inherit' />
//       <Typography variant='h3'>{title}</Typography>
//     </Stack>
//   );
// }

export const router = createBrowserRouter([
  {
    path: Routes.Home,
    element: <Root />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: Routes.AboutProject,
        element: <AboutPage />,
      },
      {
        path: Routes.Reports,
        element: <ReportsPage />,
      },
      {
        path: Routes.Report,
        element: (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_CAPTCHA_API_KEY ?? ''}
            language='ro'
          >
            <ReportPage />
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: Routes.Contact,
        element: <ContactPage />,
      },
      {
        path: '/ambulanta-apicola',
        element: <AmbulancePage />,
      },
      // {
      //   path: Routes.Policy,
      //   element: <Tbd title='Politica de confidentialitate' />,
      // },
      {
        path: Routes.Terms,
        element: <TermsPage />,
      },
      {
        path: Routes.InstallInstructions,
        element: <InstallInstructionsPage />,
      },
    ],
  },
]);
