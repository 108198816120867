import axios from 'axios';
import { API_URLS } from '../constants';
import { ContactForm } from '../types';

export const sendContactMessage = async (
  contactBody: Omit<ContactForm, 'accepted_terms'>,
): Promise<Omit<ContactForm, 'accepted_terms'>> => {
  const { data } = await axios.post(API_URLS.CONTACT, contactBody);

  return data;
};
