import axios from 'axios';
import { API_URLS } from '../constants';
import { PhytosanitaryChoice } from '../types';

export async function fetchPhytosanitaryChoices(): Promise<
  PhytosanitaryChoice[]
> {
  const { data } = await axios.get(API_URLS.PHYTOSANITARY_CHOICES);

  return data;
}
