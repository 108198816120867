import {
  createCircleAtPosition,
  createDomMarkerAtPosition,
  createMapIcon,
  incidentMarkerSvg,
} from './mapObjectsUtils';
import { BEES_RADIUS } from '../constants';

export const INCIDENT_LOCATION_KEY = 'incident-location';
export const INCIDENT_ICON = createMapIcon(incidentMarkerSvg, {
  color: '#D77D59',
  marginLeft: '-14px',
  marginTop: '-40px',
});

export const addDraggableMarkerOnTap = (
  map: H.Map,
  onTap: (point: H.geo.Point) => void,
) => {
  map.addEventListener('tap', (event: H.mapevents.Event) => {
    const coord = map.screenToGeo(
      event.currentPointer.viewportX,
      event.currentPointer.viewportY,
    );

    if (coord) {
      const mapObjects = map.getObjects();
      const filteredObjects =
        mapObjects?.filter(
          (object) => object.getData()?.key === INCIDENT_LOCATION_KEY,
        ) ?? [];

      map.removeObjects(filteredObjects);

      const marker = createDomMarkerAtPosition(coord, {
        icon: INCIDENT_ICON,
        data: { key: INCIDENT_LOCATION_KEY },
      });

      const circle = createCircleAtPosition(coord, BEES_RADIUS);

      const group = new H.map.Group({
        objects: [marker, circle],
        data: { key: INCIDENT_LOCATION_KEY },
      });

      group.draggable = true;

      map.addObject(group);
      map.getViewModel().setLookAtData(
        {
          bounds: group.getBoundingBox(),
        },
        true,
      );

      onTap(coord);
    }
  });
};
