import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchIncidents } from '../services';
import { Incident } from '../types';

export function useIncidentsQuery(
  options?: UseQueryOptions<
    Incident[] | undefined,
    Error,
    Incident[] | undefined,
    string[]
  >,
) {
  const enabled = options?.enabled ?? true;

  return useQuery(['incidents'], fetchIncidents, {
    ...options,
    enabled,
  });
}
