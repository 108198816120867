import { useBuildClusterLayer } from './useBuildClusterLayer';
import { useIncidentsQuery } from '../queries';
import { useMap } from '../store';

export const useAddIncidentMarkers = () => {
  const map = useMap();
  const buildClusterLayer = useBuildClusterLayer();

  useIncidentsQuery({
    enabled: Boolean(map),
    onSuccess: (data) => {
      buildClusterLayer(data ?? []);
    },
  });
};
