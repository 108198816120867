import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { WarningNotice } from '../types';
import { formatDate } from '../utils';

const StyledLink = styled(Link)(({ theme }) => ({
  color: '#D77D59',
  fontWeight: theme.typography.fontWeightBold,
}));

type AlertNoticeItemProps = {
  alert: WarningNotice;
};

export function AlertNoticeItem({ alert }: AlertNoticeItemProps) {
  const { typography, palette } = useTheme();

  return (
    <Stack
      border={1}
      borderColor='#F87171'
      borderRadius={1}
      flexGrow={1}
      gap={1}
      maxWidth={420}
      px={6}
      py={8}
      position='relative'
    >
      <Box
        bgcolor={palette.common.white}
        left={40}
        position='absolute'
        px={4}
        top={-12}
      >
        <Typography color='#B91C1C' fontWeight={typography.fontWeightBold}>
          Buletin de avertizare
        </Typography>
      </Box>
      <Stack direction='row' gap={1}>
        <Typography fontWeight={typography.fontWeightBold}>
          Avertizarea nr.
        </Typography>
        <Typography>{alert.registration_number}</Typography>
      </Stack>

      <Stack gap={1}>
        <Typography fontWeight={typography.fontWeightBold}>
          Data emiterii:
        </Typography>
        <Typography>
          {!alert.start_date ? '-' : formatDate(alert.start_date)}
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Typography fontWeight={typography.fontWeightBold}>
          Data încetării valabilității:
        </Typography>
        <Typography>
          {!alert.end_date ? '-' : formatDate(alert.end_date)}
        </Typography>
      </Stack>

      <Stack columnGap={1} flexWrap='wrap'>
        <Typography fontWeight={typography.fontWeightBold} flexShrink={0}>
          Cultură țintă:
        </Typography>
        <Typography>
          {alert.target_plants.map(({ name }) => name).join(', ')}
        </Typography>
      </Stack>

      <Stack>
        <Typography fontWeight={typography.fontWeightBold}>
          Produse recomandate pentru tratament:
        </Typography>
        <List disablePadding sx={{ listStyleType: 'disc', pl: 8 }}>
          {alert.used_products.map(({ id, name }) => (
            <ListItem
              key={`${id}-${name}`}
              dense
              disablePadding
              sx={{ display: 'list-item' }}
            >
              <ListItemText>{name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Stack>

      <StyledLink href={alert.document} target='_blank' underline='none'>
        Vizualizează documentul
      </StyledLink>
    </Stack>
  );
}
