import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {
  Box,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { some } from 'lodash';
import { useCallback, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { ReportForm as ReportFormType } from '../types';

export function Dropzone() {
  const { palette, typography } = useTheme();
  const {
    control,
    setValue: setFieldValue,
    watch,
  } = useFormContext<ReportFormType>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const files = watch('documents');
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      setFieldValue('documents', acceptedFiles);
    },
    [setFieldValue],
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const rejectionCodes = fileRejections.flatMap((fileRejection) =>
      fileRejection.errors.map((error) => error.code),
    );
    if (
      some(rejectionCodes, (rejection) => rejection === ErrorCode.TooManyFiles)
    ) {
      setErrorMessage(
        'Prea multe fișiere. Numărul maxim acceptat este de 10 fișiere.',
      );
      return;
    }

    if (
      some(rejectionCodes, (rejection) => rejection === ErrorCode.FileTooLarge)
    ) {
      setErrorMessage(
        'Unul sau mai multe fișiere sunt prea mari. Mărimea maximă acceptată pentru un fișier este de 10MB.',
      );
      return;
    }
    if (
      some(
        rejectionCodes,
        (rejection) => rejection === ErrorCode.FileInvalidType,
      )
    ) {
      setErrorMessage(
        'Unul sau mai multe fișiere sunt invalide. Tipurile de fișiere acceptate sunt PNG, JPEG, .tif, .docx, .pdf.',
      );
    }
  }, []);

  const onDeleteFile = useCallback(
    (file: File) => {
      const newFiles = [...files];
      const index = newFiles.map((f) => f.name).indexOf(file.name);
      newFiles.splice(index, 1);

      setFieldValue('documents', newFiles, { shouldDirty: true });
    },
    [files, setFieldValue],
  );

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      image: ['image/jpeg', 'image/png', 'image/heif', 'image/heic'],
      files: ['.doc', '.docx', '.pptx', '.txt', '.pdf', '.tif', '.tiff'],
    },
    maxFiles: 10,
    maxSize: 10485760,
    onDropAccepted,
    onDropRejected,
    onFileDialogOpen: () => setErrorMessage(''),
  });

  return (
    <Box>
      <Stack
        alignItems='center'
        direction='row'
        mx={20}
        py={{ xs: 0, sm: 4 }}
        sx={{ cursor: 'pointer' }}
        {...getRootProps()}
      >
        <Controller
          control={control}
          name='documents'
          render={() => <input {...getInputProps()} />}
        />

        <Box bgcolor={palette.grey[300]} flexGrow={1} height='1px' />
        <Stack
          alignItems='center'
          border={1}
          borderColor={palette.grey[300]}
          borderRadius={100}
          color={palette.grey[800]}
          direction='row'
          gap={1}
          px={3}
          py={2}
          sx={{ '&:hover': { bgcolor: palette.grey[100] } }}
        >
          <AddRoundedIcon />
          <Typography
            color={palette.grey[700]}
            fontWeight={typography.fontWeightBold}
            variant='body2'
          >
            Adaugă fișiere
          </Typography>
        </Stack>
        <Box bgcolor={palette.grey[300]} flexGrow={1} height='1px' />
      </Stack>
      <FormHelperText error>{errorMessage || ' '}</FormHelperText>
      <Stack direction='row' flexWrap='wrap'>
        {files?.map((file) => (
          <Stack
            alignItems='center'
            color={palette.grey[600]}
            direction='row'
            key={file.name}
            gap={1}
          >
            <Typography
              color='#059669'
              fontStyle='italic'
              fontWeight={typography.fontWeightBold}
              variant='caption'
            >
              {file.name}
            </Typography>
            <IconButton onClick={() => onDeleteFile(file)} size='small'>
              <DeleteForeverRoundedIcon fontSize='small' />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
