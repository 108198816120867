import { MapDiscoverResult } from '../types';

export const isDomMarker = (
  object: H.map.Object | H.Map | null,
): object is H.map.DomMarker =>
  (object as H.map.DomMarker)?.getData !== undefined;

export const isDomGroup = (
  object: H.map.Object | H.Map | null,
): object is H.map.Group => (object as H.map.Group)?.getObjects !== undefined;

export const isMapDiscoverResult = (o: unknown): o is MapDiscoverResult =>
  (o as MapDiscoverResult)?.address !== undefined;

export const isObjectLayer = (
  layer: H.map.layer.Layer,
): layer is H.map.layer.ObjectLayer =>
  (layer as H.map.layer.ObjectLayer)?.requestDomMarkers !== undefined;
