import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchPhytosanitaryChoices } from '../services';
import { PhytosanitaryChoice } from '../types';

export function usePhytosanitaryChoicesQuery(
  options?: UseQueryOptions<
    PhytosanitaryChoice[],
    unknown,
    PhytosanitaryChoice[]
  >,
) {
  return useQuery({
    queryKey: ['phytosanitary-choices'],
    queryFn: fetchPhytosanitaryChoices,
    ...options,
  });
}
