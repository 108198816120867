import { Routes, RoutesConfig } from '../types';

export const routesConfig: RoutesConfig = [
  {
    label: 'Acasă',
    route: Routes.Home,
  },
  {
    label: 'Despre proiect',
    route: Routes.AboutProject,
  },
  {
    label: 'Incidente raportate',
    route: Routes.Reports,
  },
  {
    label: 'Raportează',
    route: Routes.Report,
  },
  {
    label: 'Contact',
    route: Routes.Contact,
  },
  // {
  //   isFooterLink: true,
  //   label: 'Politică de confidențialitate',
  //   route: Routes.Policy,
  // },
  {
    isFooterLink: true,
    label: 'Termeni și condiții',
    route: Routes.Terms,
  },
];
