import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchVandalismChoices } from '../services';
import { VandalismChoice } from '../types';

export function useVandalismChoicesQuery(
  options?: UseQueryOptions<VandalismChoice[], unknown, VandalismChoice[]>,
) {
  return useQuery({
    queryKey: ['vandalism-choices'],
    queryFn: fetchVandalismChoices,
    ...options,
  });
}
