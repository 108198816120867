import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

type DetailsPanelContainerProps = {
  onClose: () => void;
  showBoxShadow?: boolean;
  showCloseButton?: boolean;
};

export function DetailsPanelContainer({
  children,
  onClose,
  showBoxShadow,
  showCloseButton,
}: PropsWithChildren<DetailsPanelContainerProps>) {
  return (
    <Stack
      borderRadius={2}
      boxShadow={showBoxShadow ? 4 : undefined}
      gap={4}
      height={1}
      minWidth={{ xs: 0, sm: 360 }}
      overflow='auto'
      position='relative'
      px={4}
      py={2}
    >
      {showCloseButton && (
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Stack>
  );
}

DetailsPanelContainer.defaultProps = {
  showBoxShadow: false,
  showCloseButton: false,
};
