import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { createIncidentsMapSlice } from './createIncidentsMapSlice';
import { createMapSlice } from './createMapSlice';
import { createReportIncidentMapSlice } from './createReportIncidentMapSlice';
import { createReportIncidentSlice } from './createReportIncidentSlice';
import { AppStore, InstallAppStore } from './types';

export const useStore = create<AppStore>()((...a) => ({
  ...createMapSlice(...a),
  ...createIncidentsMapSlice(...a),
  ...createReportIncidentSlice(...a),
  ...createReportIncidentMapSlice(...a),
}));

export const useInstallAppStore = create<InstallAppStore>()(
  persist(
    (set) => ({
      isInstalled: false,
      setIsInstalled: () => set({ isInstalled: true }),
    }),
    {
      name: 'is-app-installed',
      partialize: (state) => ({ isInstalled: state.isInstalled }),
    },
  ),
);
