import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Link, Skeleton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type DetailsPanelTitle = {
  href?: string;
  isLink: boolean;
  isLoading: boolean;
  showIcon: boolean;
  subtitle: ReactNode;
  title: string;
};

export function DetailsPanelTitle({
  href = '',
  isLink,
  isLoading,
  showIcon,
  subtitle,
  title,
}: DetailsPanelTitle) {
  const subtitleContent =
    typeof subtitle === 'string' ? (
      <Typography variant='caption'>{subtitle}</Typography>
    ) : (
      subtitle
    );
  return (
    <Stack alignItems='center' direction='row' gap={2}>
      {showIcon && <LocationOnOutlinedIcon />}
      <Stack sx={{ flexGrow: 1 }}>
        {isLoading ? (
          <Skeleton variant='text' sx={{ fontSize: '1.25rem' }} />
        ) : (
          <Typography
            component={isLink ? Link : 'div'}
            href={isLink ? href : undefined}
            fontWeight={700}
            sx={{ cursor: isLink ? 'pointer' : 'initial' }}
            target={isLink ? '_blank' : undefined}
            variant='h6'
          >
            {title}
          </Typography>
        )}
        <Stack direction='row' gap={1}>
          {isLoading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : (
            subtitleContent
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

DetailsPanelTitle.defaultProps = {
  href: '',
};
