import ReactGA from 'react-ga4';

export const initializeGA = () => {
  if (process.env.REACT_APP_ANALYTICS_APISANA) {
    ReactGA.initialize([
      {
        trackingId: process.env.REACT_APP_ANALYTICS_APISANA,
      },
    ]);
  }
};
