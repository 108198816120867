import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Fab,
  Fade,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { range } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import fundingSrc from '../assets/img/funding_2480x1305.jpg';
import { AlertNoticeItem, Divider } from '../components';
import { COUNTIES } from '../constants';
import {
  DEFAULT_ALERTS_STROKE_COLOR,
  HIGH_ALERTS_FILL_COLOR,
  LOW_ALERTS_FILL_COLOR,
  MEDIUM_ALERTS_FILL_COLOR,
  useInitializeAlertsMap,
} from '../hooks';
import { useWarningNoticesQuery } from '../queries';
import { Counties, Routes } from '../types';

const reportedYears = range(new Date().getFullYear(), 2022, -1);

const MapContainer = styled(Box)(({ theme }) => ({
  '& > div': {
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
  },
}));

export function HomePage() {
  const [selectedCounty, setSelectedCounty] = useState<Counties>();
  const gridRef = useRef<HTMLDivElement>(null);
  const onCountyClick = useCallback((county: Counties) => {
    setSelectedCounty(county);
    gridRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  const [reportedYear, setReportedYear] = useState(
    String(new Date().getFullYear()),
  );

  const { data: warningNotices } = useWarningNoticesQuery(reportedYear);

  const warningNoticesPerCounty = useMemo(
    () =>
      warningNotices?.reduce((acc, { county }) => {
        acc[county] = acc[county] ? acc[county] + 1 : 1;
        return acc;
      }, {} as Record<Counties, number>),
    [warningNotices],
  );

  const { mapRef } = useInitializeAlertsMap(
    warningNoticesPerCounty,
    onCountyClick,
  );

  const filteredWarningNotices = useMemo(
    () =>
      warningNotices?.filter(({ county }) =>
        !selectedCounty ? true : selectedCounty === county || county === 'RO',
      ) ?? [],
    [selectedCounty, warningNotices],
  );

  const onScrollToMap = useCallback(() => {
    mapRef.current?.scroll();
    mapRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [mapRef]);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Stack gap={4} py={5}>
      <Grid
        columnSpacing={{ xs: 0, md: 4 }}
        container
        rowSpacing={{ xs: 4, md: 0 }}
      >
        <Grid item xs={12} md>
          <Stack gap={4}>
            <Typography
              color='primary'
              fontSize={{ xs: 40, md: 52 }}
              textAlign='center'
              variant='h2'
            >
              Bine ai venit!
            </Typography>
            <Typography textAlign='center'>
              Aceasta este pagina de internet a proiectului APISANA - laborator
              mobil de recoltare si conservare a probelor colectate pentru
              evaluarea toxicitatii cuturilor agricole asupra albinelor
              melifere. Proiectul este finanțat prin P.N.D.R. submăsura 16.1-
              Sprijin pentru înființarea și funcționarea grupurilor operaționale
              (GO), pentru dezvoltarea de proiecte pilot, noi produse.
            </Typography>
            <Typography textAlign='center'>
              Un proiect realizat de un consorțiu în care ROMAPIS are rolul de
              lider de coordonator.
            </Typography>

            <Typography textAlign='center' fontWeight='bold'>
              Ești victima unui caz de intoxicare a albinelor? Putem să te
              ajutăm, mergi la pagina{' '}
              <Link to={Routes.Report} style={{ textDecoration: 'none' }}>
                <Typography
                  color='primary'
                  component='span'
                  fontWeight='bold'
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Raportează
                </Typography>
              </Link>
              .
            </Typography>
            <Box textAlign='center'>
              <Typography>
                Vrei să verifici avertizările emise de sucursala fitosanitară
                din județul tău?
              </Typography>
              <Button onClick={onScrollToMap} variant='contained'>
                Vezi hartă
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md>
          <Box
            borderRadius={2}
            boxShadow={3}
            height={{ xs: 270, sm: 400 }}
            sx={{
              backgroundImage: `url(${fundingSrc})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
            width='100%'
          />
        </Grid>
      </Grid>
      <Divider />

      <Box>
        <Typography textAlign='center' gutterBottom>
          Dă click pe un județ pentru a afla mai multe detalii despre
          avertizarea emisă de sucursala fitosanitară județeană
        </Typography>

        <Grid container alignItems='flex-end'>
          <Grid
            item
            xs={12}
            md={10}
            alignItems='center'
            direction='row'
            component={Stack}
            gap={2}
          >
            <Typography fontWeight='bold' variant='body2'>
              Legendă:
            </Typography>
            <Box
              bgcolor={LOW_ALERTS_FILL_COLOR}
              border={1}
              borderColor={DEFAULT_ALERTS_STROKE_COLOR}
              borderRadius={1}
              height={20}
              width={60}
            />
            <Typography variant='body2'>1 - 5 alerte</Typography>
            <Box
              bgcolor={MEDIUM_ALERTS_FILL_COLOR}
              border={1}
              borderColor={DEFAULT_ALERTS_STROKE_COLOR}
              borderRadius={1}
              height={20}
              width={60}
            />
            <Typography variant='body2'>6 - 10 alerte</Typography>
            <Box
              bgcolor={HIGH_ALERTS_FILL_COLOR}
              border={1}
              borderColor={DEFAULT_ALERTS_STROKE_COLOR}
              borderRadius={1}
              height={20}
              width={60}
            />
            <Typography variant='body2'>&#8250; 10 alerte</Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth size='small'>
              <Select
                onChange={(event) => setReportedYear(event.target.value)}
                value={reportedYear}
                sx={{
                  '& .MuiInputBase-input': {
                    py: 0,
                  },
                }}
              >
                <MenuItem value='' disabled>
                  <ListItemText primary='Selectează anul de raportare' />
                </MenuItem>
                {reportedYears.map((year) => (
                  <MenuItem key={year} value={String(year)}>
                    <ListItemText primary={year} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <MapContainer flexGrow={1} height={560} ref={mapRef} />

      <Grid columnSpacing={4} container ref={gridRef} rowSpacing={6} mt={4}>
        {selectedCounty && (
          <Grid item xs={12}>
            <Typography variant='h5'>
              {!filteredWarningNotices.length
                ? 'Nu există buletine de avertizare pentru județul'
                : 'Buletine de avertizare pentru județul'}{' '}
              <strong>
                {COUNTIES.find(({ id }) => id === selectedCounty)?.label}
              </strong>
              {' | '}
              <Button
                component='span'
                onClick={() => setSelectedCounty(undefined)}
                size='small'
              >
                Afișează toate buletinele
              </Button>
            </Typography>
          </Grid>
        )}
        {filteredWarningNotices.map((alert) => (
          <Grid item key={alert.pk} xs={12} sm={4}>
            <AlertNoticeItem key={alert.pk} alert={alert} />
          </Grid>
        ))}
      </Grid>

      <Fade in={trigger}>
        <Box
          onClick={onScrollToMap}
          role='presentation'
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <Fab color='primary' size='small'>
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
    </Stack>
  );
}
