import { Stack, styled, useTheme } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Routes } from '../types';
import { routesConfig } from '../utils';

const StyledNavLink = styled(NavLink)<NavLinkProps>(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.primary,
    fontFamily: 'Inter',
    padding: spacing(1, 0),
    width: 'fit-content',

    '&:hover': {
      color: palette.text.secondary,
    },
  }),
);

export function FooterNavLinks() {
  const { palette } = useTheme();

  return (
    <Stack component='nav'>
      {routesConfig
        .filter(({ route }) => route !== Routes.Home)
        .map(({ label, route }) => (
          <StyledNavLink
            end
            key={route}
            style={({ isActive }) =>
              isActive
                ? { color: palette.primary.main, fontWeight: 600 }
                : undefined
            }
            to={route}
          >
            {label}
          </StyledNavLink>
        ))}
    </Stack>
  );
}
