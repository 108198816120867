import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export function AmbulanceLink() {
  return (
    <Link
      to='/ambulanta-apicola'
      style={{ textDecoration: 'none' }}
      target='_blank'
    >
      <Typography
        component='span'
        color='error.main'
        fontWeight='bold'
        fontSize='inherit'
        sx={{
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        Ambulanței apicole
      </Typography>
    </Link>
  );
}
