import { isObjectLayer } from './typeGuards';
import { BEE_HIVE_MARKER_KEY } from '../constants';

export const getMapObjectsInBoundingBox = (
  map: H.Map,
  bBox: H.geo.Rect,
): H.map.AbstractMarker[] => {
  const objects: H.map.AbstractMarker[] = [];

  map
    .getLayers()
    .asArray()
    .forEach((layer: H.map.layer.Layer) => {
      if (isObjectLayer(layer)) {
        layer
          ?.requestDomMarkers(bBox, map.getZoom(), true, new H.math.Point(0, 0))
          .markers.forEach((marker) => {
            if (marker.getData().key !== BEE_HIVE_MARKER_KEY) {
              objects.push(marker);
            }
          });
      }
    });

  return objects;
};
