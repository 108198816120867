import MenuIcon from '@mui/icons-material/Menu';
import {
  alpha,
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { MouseEventHandler, useCallback, useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Brand } from './Brand';
import { NAVIGATION_BAR_HEIGHT } from '../constants';
import { routesConfig } from '../utils';

const StyledNavLink = styled(NavLink)<NavLinkProps>(({ theme }) => ({
  color: theme.palette.common.black,
  fontFamily: 'Inter',
  fontSize: 20,
  textDecoration: 'none',

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  zIndex: theme.zIndex.appBar - 1,

  '& .MuiBackdrop-root': {
    background: alpha(theme.palette.common.black, 0.5),
  },
}));

export function NavigationBar() {
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toggleMenu = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      const target = !anchorEl ? event.currentTarget : null;
      setAnchorEl(target);
    },
    [anchorEl],
  );

  return (
    <AppBar component='nav' sx={{ bgcolor: 'common.white' }}>
      <Container>
        <Stack
          alignItems='center'
          direction='row'
          display={{ xs: 'none', md: 'flex' }}
          gap={6}
          height={NAVIGATION_BAR_HEIGHT}
          justifyContent='flex-end'
        >
          <Brand />
          {routesConfig.map(
            ({ isFooterLink, label, route }) =>
              !isFooterLink && (
                <StyledNavLink
                  end
                  key={route}
                  style={({ isActive }) =>
                    isActive ? { color: palette.primary.main } : undefined
                  }
                  to={route}
                >
                  {label}
                </StyledNavLink>
              ),
          )}
        </Stack>

        <Box
          alignItems='center'
          display={{ xs: 'flex', md: 'none' }}
          justifyContent='flex-end'
        >
          <Brand />
          <IconButton onClick={toggleMenu} size='large'>
            <MenuIcon />
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            elevation={1}
            onClose={closeMenu}
            open={Boolean(anchorEl)}
            PaperProps={{
              sx: {
                left: '0 !important',
                maxWidth: '100%',
                right: 0,
                width: '100%',
              },
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          >
            {routesConfig.map(
              ({ isFooterLink, label, route }) =>
                !isFooterLink && (
                  <MenuItem
                    component={StyledNavLink}
                    key={route}
                    onClick={closeMenu}
                    to={route}
                  >
                    {label}
                  </MenuItem>
                ),
            )}
          </StyledMenu>
        </Box>
      </Container>
    </AppBar>
  );
}
