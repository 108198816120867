import {
  Container,
  Autocomplete,
  TextField,
  Typography,
  Snackbar,
  Alert,
  AutocompleteChangeReason,
  UseAutocompleteProps,
} from '@mui/material';
import { debounce, first } from 'lodash';
import { useState, useCallback, SyntheticEvent } from 'react';
import { useShowBeeHiveAtPosition } from '../hooks';
import {
  useGeocodeByAddressQuery,
  useMapAutocompleteResultsQuery,
} from '../queries';
import { MapDiscoverResult } from '../types';
import { isMapDiscoverResult } from '../utils';

const DEFAULT_OPTION: Pick<MapDiscoverResult, 'title'> = {
  title: 'Folosește locația curentă',
};

export function SearchBeeHiveLocation() {
  const [options, setOptions] = useState<
    ({ title: string } | MapDiscoverResult)[]
  >([DEFAULT_OPTION]);

  const [searchAddress, setSearchAddress] = useState('');
  useMapAutocompleteResultsQuery(searchAddress, {
    onSuccess: ({ items }) => setOptions([DEFAULT_OPTION, ...items]),
    enabled: searchAddress !== DEFAULT_OPTION.title,
  });

  const showBeeHivePosition = useShowBeeHiveAtPosition();
  const [selectedAddress, setSelectedAddress] = useState('');
  useGeocodeByAddressQuery(selectedAddress, {
    onSuccess: ({ items }) => {
      const firstResult = first(items);
      if (firstResult) {
        const { lat, lng } = firstResult.position;
        showBeeHivePosition({ lat, lng });
      }
    },
    enabled: Boolean(selectedAddress),
  });

  const [value, setValue] = useState<
    { title: string } | MapDiscoverResult | null
  >(null);
  const [openMessage, setOpenMessage] = useState(false);

  const onInputChangeHandler = useCallback<
    Required<
      UseAutocompleteProps<
        { title: string } | MapDiscoverResult,
        false,
        false,
        false
      >
    >['onInputChange']
  >((_, newInputValue) => setSearchAddress(newInputValue), []);

  const debouncedOnInputChangeHandler = debounce(onInputChangeHandler, 400);

  const onOptionChangeHandler = useCallback(
    (
      event: SyntheticEvent,
      newValue: MapDiscoverResult | Pick<MapDiscoverResult, 'title'> | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (reason === 'clear') {
        setValue(null);
        return;
      }

      setValue(newValue);
      if (isMapDiscoverResult(newValue)) {
        setSelectedAddress(newValue.title);
      } else {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            const { latitude: lat, longitude: lng } = coords;
            showBeeHivePosition({ lat, lng });
          },
          () => setOpenMessage(true),
        );
      }
    },
    [showBeeHivePosition],
  );

  return (
    <>
      <Container maxWidth='sm'>
        <Autocomplete
          disablePortal
          filterOptions={(x) => x}
          getOptionLabel={(option) => {
            if (isMapDiscoverResult(option)) {
              return option.address.label;
            }
            return option.title;
          }}
          onInputChange={debouncedOnInputChangeHandler}
          options={options}
          onChange={onOptionChangeHandler}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Unde ești acum?'
              InputProps={{ ...params.InputProps, notched: false }}
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              key={isMapDiscoverResult(option) ? option.id : option.title}
            >
              <Typography>
                {isMapDiscoverResult(option)
                  ? option.address.label
                  : option.title}
              </Typography>
            </li>
          )}
          value={value}
        />
      </Container>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        onClose={() => setOpenMessage(false)}
        open={openMessage}
      >
        <Alert severity='warning' sx={{ width: '100%' }}>
          Locația este blocată. Pentru a folosi această opțiune permite
          folosirea locației și încearcă din nou.
        </Alert>
      </Snackbar>
    </>
  );
}
