import {
  Box,
  Divider as DividerCore,
  DividerProps,
  styled,
} from '@mui/material';
import { HoneyCombs } from './HoneyCombs';

const StyledDivider = styled(DividerCore)<DividerProps>(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.divider,
  fontSize: 50,
  '&:before, &:after': {
    backgroundColor: theme.palette.divider,
    border: 'none',
    height: 4,
  },
}));

export function Divider() {
  return (
    <StyledDivider>
      <Box alignItems='center' display='flex'>
        <HoneyCombs />
      </Box>
    </StyledDivider>
  );
}
