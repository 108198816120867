import axios from 'axios';
import { API_URLS } from '../constants';

export type AddIncidentDocumentsRequest = {
  alert: string;
  file: File;
};

export const addIncidentDocuments = async ({
  alert,
  file,
}: AddIncidentDocumentsRequest): Promise<void> => {
  const bodyFormData = new FormData();

  bodyFormData.append('alert', alert);
  bodyFormData.append('file', file);

  await axios.post(API_URLS.ALERT_DOCUMENTS, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
