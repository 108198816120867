import { SvgIcon } from '@mui/material';

export function HoneyCombs() {
  return (
    <SvgIcon
      color='inherit'
      viewBox='30 -10 120 120'
      fontSize='inherit'
      sx={{ width: 100 }}
    >
      <path
        d='M66.9325 53L94.0675 53C94.476 52.9978 94.8768 52.8875 95.2293 52.6801C95.5818 52.4728 95.8736 52.1758 96.0754 51.819L109.7 27.6234C109.897 27.2818 110 26.8944 110 26.5C110 26.1056 109.897 25.7182 109.7 25.3766L96.0754 1.18098C95.8736 0.824237 95.5818 0.527221 95.2293 0.319879C94.8768 0.112538 94.476 0.00220267 94.0675 -6.96431e-07L66.9325 -1.88254e-06C66.524 0.00220144 66.1232 0.112537 65.7707 0.319878C65.4182 0.52722 65.1264 0.824236 64.9246 1.18098L51.2998 25.3766C51.1034 25.7182 51 26.1056 51 26.5C51 26.8944 51.1034 27.2818 51.2998 27.6234L64.9246 51.819C65.1264 52.1758 65.4182 52.4728 65.7707 52.6801C66.1232 52.8875 66.524 52.9978 66.9325 53Z'
        fill='currentColor'
      />
      <path d='M15.9325 80L43.0675 80C43.476 79.9978 43.8768 79.8875 44.2293 79.6801C44.5818 79.4728 44.8736 79.1758 45.0754 78.819L58.7003 54.6234C58.8966 54.2818 59 53.8944 59 53.5C59 53.1056 58.8966 52.7182 58.7003 52.3766L45.0754 28.181C44.8736 27.8242 44.5818 27.5272 44.2293 27.3199C43.8768 27.1125 43.476 27.0022 43.0675 27L15.9325 27C15.524 27.0022 15.1232 27.1125 14.7707 27.3199C14.4182 27.5272 14.1264 27.8242 13.9246 28.181L0.29975 52.3766C0.103381 52.7182 2.67358e-06 53.1056 2.65635e-06 53.5C2.63911e-06 53.8944 0.103381 54.2818 0.29975 54.6234L13.9246 78.819C14.1264 79.1758 14.4182 79.4728 14.7707 79.6801C15.1232 79.8875 15.524 79.9978 15.9325 80Z' />
      <path d='M117.932 80L145.068 80C145.476 79.9978 145.877 79.8875 146.229 79.6801C146.582 79.4728 146.874 79.1758 147.075 78.819L160.7 54.6234C160.897 54.2818 161 53.8944 161 53.5C161 53.1056 160.897 52.7182 160.7 52.3766L147.075 28.181C146.874 27.8242 146.582 27.5272 146.229 27.3199C145.877 27.1125 145.476 27.0022 145.068 27L117.932 27C117.524 27.0022 117.123 27.1125 116.771 27.3199C116.418 27.5272 116.126 27.8242 115.925 28.181L102.3 52.3766C102.103 52.7182 102 53.1056 102 53.5C102 53.8944 102.103 54.2818 102.3 54.6234L115.925 78.819C116.126 79.1758 116.418 79.4728 116.771 79.6801C117.123 79.8875 117.524 79.9978 117.932 80Z' />
      <path d='M66.9325 110L94.0675 110C94.476 109.998 94.8768 109.887 95.2293 109.68C95.5818 109.473 95.8736 109.176 96.0754 108.819L109.7 84.6234C109.897 84.2818 110 83.8944 110 83.5C110 83.1056 109.897 82.7182 109.7 82.3766L96.0754 58.181C95.8736 57.8242 95.5818 57.5272 95.2293 57.3199C94.8768 57.1125 94.476 57.0022 94.0675 57L66.9325 57C66.524 57.0022 66.1232 57.1125 65.7707 57.3199C65.4182 57.5272 65.1264 57.8242 64.9246 58.181L51.2998 82.3766C51.1034 82.7182 51 83.1056 51 83.5C51 83.8944 51.1034 84.2818 51.2998 84.6234L64.9246 108.819C65.1264 109.176 65.4182 109.473 65.7707 109.68C66.1232 109.887 66.524 109.998 66.9325 110Z' />
    </SvgIcon>
  );
}
