import H from '@here/maps-api-for-javascript';
import { omit } from 'lodash';
import { StateCreator } from 'zustand';
import { MapSlice } from './types';

export const createMapSlice: StateCreator<MapSlice> = (set) => ({
  disposeMap: () =>
    set((state) => omit(state, ['mapBehavior', 'map', 'ui']), true),
  setMap: (map: H.Map, mapBehavior: H.mapevents.Behavior, mapUI: H.ui.UI) =>
    set({ map, mapBehavior, mapUI }),
});
