import { useCallback, useLayoutEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { DEFAULT_MAP_OPTIONS } from '../constants';
import { useReportMap, useReportIncidentSlice } from '../store';
import { addDraggableMarkerOnTap, addDragMarkerHandlers } from '../utils';

export function useInitializeReportIncidentMap() {
  const mapRef = useRef<HTMLElement>();
  const { map, setMap, disposeMap } = useReportMap();
  const { setIncidentLocation } = useReportIncidentSlice();

  const onResize = useCallback(() => {
    map?.getViewPort().resize();
  }, [map]);

  useResizeDetector({
    onResize,
    skipOnMount: true,
    targetRef: mapRef,
  });

  useLayoutEffect(() => {
    if (!process.env.REACT_APP_HERE_MAPS_API_KEY || !mapRef.current) {
      return undefined;
    }

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });

    const layers = platform.createDefaultLayers();

    const hMap = new H.Map(mapRef.current, layers.raster.satellite.map, {
      ...DEFAULT_MAP_OPTIONS,
    });

    const events = new H.mapevents.MapEvents(hMap);
    const behavior = new H.mapevents.Behavior(events);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ui = H.ui.UI.createDefault(hMap, layers);

    setMap(hMap);

    addDragMarkerHandlers(hMap, behavior, setIncidentLocation);

    addDraggableMarkerOnTap(hMap, setIncidentLocation);

    return () => {
      hMap.dispose();
      disposeMap();
    };
  }, [disposeMap, setIncidentLocation, setMap]);

  return { mapRef };
}
