import { Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type SectionTitleProps = {
  subtitle?: string;
  title: ReactNode;
};
export function SectionTitle({ subtitle, title }: SectionTitleProps) {
  const { typography } = useTheme();

  return (
    <>
      {typeof title === 'string' ? (
        <Typography
          fontSize={{
            xs: typography.pxToRem(32),
            md: typography.pxToRem(48),
          }}
          fontWeight={typography.fontWeightBold}
          variant='h3'
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      {Boolean(subtitle) && <Typography>{subtitle}</Typography>}
    </>
  );
}
SectionTitle.defaultProps = {
  subtitle: '',
};
