import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';
import { FooterNavLinks } from './FooterNavLinks';
import logoRomapisSrc from '../assets/img/logo_romapis.png';

export function Footer() {
  return (
    <Box borderTop='1px solid #D4D4D8'>
      <Container component='footer'>
        <Grid container py={4} rowSpacing={6}>
          <Grid item md={5} sm xs={12}>
            <Stack gap={3}>
              <Stack gap={2}>
                <Typography>Un proiect realizat de</Typography>
                <Box>
                  <Link href='https://www.romapis.org/' target='_blank'>
                    <img src={logoRomapisSrc} alt='ROMAPIS' />
                  </Link>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md sm xs={12}>
            <FooterNavLinks />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
