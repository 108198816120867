import { memo } from 'react';
import { MapContainer } from './MapContainer';
import { useInitializeHomeMap } from '../hooks';

type MapProps = {
  height: number;
};

// eslint-disable-next-line react/display-name
export const Map = memo(({ height }: MapProps) => {
  const mapRef = useInitializeHomeMap();

  return <MapContainer flexGrow={1} height={height} ref={mapRef} />;
});
