import { useInstallAppStore, useStore } from './useStore';

export const useMap = () => {
  const disposeMap = useStore((state) => state.disposeMap);
  const map = useStore((state) => state.map);
  const mapBehavior = useStore((state) => state.mapBehavior);
  const mapUI = useStore((state) => state.mapUI);
  const setMap = useStore((state) => state.setMap);

  return { disposeMap, map, mapBehavior, mapUI, setMap };
};

export const useReportMap = () => {
  const disposeMap = useStore((state) => state.disposeReportMap);
  const map = useStore((state) => state.reportMap);
  const setMap = useStore((state) => state.setReportMap);

  return { disposeMap, map, setMap };
};

export const useIncidentsMap = () => {
  const clearSelectedMarker = useStore((state) => state.clearSelectedMarker);
  const clearHiveData = useStore((state) => state.clearHiveData);
  const hiveData = useStore((state) => state.hiveData);
  const selectedMarker = useStore((state) => state.selectedMarker);
  const setHiveData = useStore((state) => state.setHiveData);
  const setSelectedMarker = useStore((state) => state.setSelectedMarker);

  return {
    clearSelectedMarker,
    clearHiveData,
    hiveData,
    selectedMarker,
    setHiveData,
    setSelectedMarker,
  };
};

export const useReportIncidentSlice = () => {
  const incidentLocation = useStore((state) => state.incidentLocation);
  const setIncidentLocation = useStore((state) => state.setIncidentLocation);
  const clearIncidentLocation = useStore(
    (state) => state.clearIncidentLocation,
  );

  return { clearIncidentLocation, incidentLocation, setIncidentLocation };
};

export const useInstallAppSlice = () => {
  const isInstalled = useInstallAppStore((state) => state.isInstalled);
  const setIsInstalled = useInstallAppStore((state) => state.setIsInstalled);

  return { isInstalled, setIsInstalled };
};
