import {
  Alert,
  AlertProps,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@mui/material';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSendContactMessage } from '../mutations';
import { ContactForm as ContactFormType, Routes } from '../types';

export function ContactForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [alert, setAlert] = useState<{
    open: boolean;
    message: string;
    severity: AlertProps['severity'];
  }>();

  const { control, handleSubmit, reset } = useForm<ContactFormType>({
    defaultValues: {
      email: '',
      message: '',
      name: '',
      phone_number: '',
      accepted_terms: false,
      source_site: 'apisana',
    },
    reValidateMode: 'onChange',
  });

  const { mutate: sendContactMessage, isLoading } = useSendContactMessage();

  const onSubmit = useCallback<SubmitHandler<ContactFormType>>(
    async (data) => {
      const token = await executeRecaptcha?.();
      if (token) {
        sendContactMessage(omit(data, 'accepted_terms'), {
          onSuccess: () => {
            reset();
            setAlert({
              open: true,
              message: 'Mesajul a fost trimis cu succes',
              severity: 'success',
            });
          },
          onError: () =>
            setAlert({
              open: true,
              message: 'Am întâmpinat o problemă. Încearcă din nou',
              severity: 'error',
            }),
        });
      }
    },
    [executeRecaptcha, reset, sendContactMessage],
  );

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={6}>
        <Grid item xs={12} sm>
          <Controller
            control={control}
            name='name'
            render={({ field, fieldState }) => (
              <FormControl>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Nume complet:
                </InputLabel>
                <Box>
                  <OutlinedInput
                    error={Boolean(fieldState.error)}
                    id={field.name}
                    {...field}
                  />
                  <FormHelperText error={Boolean(fieldState.error)}>
                    {fieldState.error?.message ?? ' '}
                  </FormHelperText>
                </Box>
              </FormControl>
            )}
            rules={{
              maxLength: {
                value: 100,
                message: 'Numele poate avea maximum 100 de caractere',
              },
              required: { value: true, message: 'Acest câmp este obligatoriu' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm>
          <Controller
            control={control}
            name='email'
            render={({ field, fieldState }) => (
              <FormControl>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Email:
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              maxLength: {
                value: 100,
                message: 'Adresa de email poate avea maximum 100 de caractere',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Adresa de email nu este validă',
              },
              required: { value: true, message: 'Acest câmp este obligatoriu' },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='phone_number'
            render={({ field, fieldState }) => (
              <FormControl>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Telefon (opțional):
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  type='tel'
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /\d{10}/,
                message: 'Numărul de telefon nu este valid',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='message'
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={field.name} variant='outlined'>
                  Mesajul tău:
                </InputLabel>
                <OutlinedInput
                  error={Boolean(fieldState.error)}
                  id={field.name}
                  multiline
                  rows={4}
                  {...field}
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: { value: true, message: 'Acest câmp este obligatoriu' },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='accepted_terms'
            render={({ field, fieldState }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox checked={field.value} {...field} />}
                  label={
                    <Typography>
                      Prin această bifă îți exprimi acordul ca datele furnizate
                      de tine prin acest formular să fie procesate exclusiv in
                      scopul de a încărca în platformă acest document și ca
                      echipa ROMPAIS să te contacteze doar în legătură cu
                      această submisie. Aici puteți găsi{' '}
                      <Typography
                        color='#198ECF'
                        component={Link}
                        target='_blank'
                        to={Routes.Terms}
                        sx={{ textDecoration: 'none' }}
                      >
                        regulamentul nostru cu privire la prelucrarea datelor cu
                        caracter personal
                      </Typography>
                      .
                    </Typography>
                  }
                />
                <FormHelperText error={Boolean(fieldState.error)}>
                  {fieldState.error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
            rules={{
              required: { value: true, message: 'Acest câmp este obligatoriu' },
            }}
          />
        </Grid>
        <Grid item xs={12} textAlign='right'>
          <Button
            disabled={isLoading}
            type='submit'
            variant='contained'
            sx={{ minWidth: 88 }}
          >
            {!isLoading ? 'Trimite' : <CircularProgress size={24.5} />}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
        open={alert?.open}
        onClose={() => setAlert(undefined)}
      >
        <Alert variant='filled' severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
