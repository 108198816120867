import { useLayoutEffect, useRef } from 'react';
import { ROMAPIS_LOCATION } from '../constants';

const options = {
  center: ROMAPIS_LOCATION,
  pixelRatio: window.devicePixelRatio || 1,
  zoom: 17,
};

export function useInitializeContactMap() {
  const mapRef = useRef<HTMLElement>();

  useLayoutEffect(() => {
    if (!process.env.REACT_APP_HERE_MAPS_API_KEY || !mapRef.current) {
      return undefined;
    }

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });

    const layers = platform.createDefaultLayers();

    const hMap = new H.Map(mapRef.current, layers.vector.normal.map, {
      ...options,
    });

    const centerMarker = new H.map.Marker(options.center);
    hMap.addObject(centerMarker);

    return () => {
      hMap.dispose();
    };
  }, []);

  return { mapRef };
}
