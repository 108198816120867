import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { COUNTRY_CODE } from '../constants';
import { fetchMapAutocompleteResults } from '../services';
import { MapDiscoverResults } from '../types';

export const useMapAutocompleteResultsQuery = (
  address: string,
  options?: UseQueryOptions<
    MapDiscoverResults,
    Error,
    MapDiscoverResults,
    string[]
  >,
) => {
  const enabled = Boolean(address) && (options?.enabled ?? true);

  return useQuery(
    ['map-autocomplete-results', address],
    () =>
      fetchMapAutocompleteResults({
        in: COUNTRY_CODE,
        q: address,
      }),
    {
      ...options,
      enabled,
    },
  );
};
