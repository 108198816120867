import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Divider, SearchBeeHiveLocation } from '../components';
import { IncidentsMap } from '../components/IncidentsMap';
import { useAddIncidentMarkers, useShowBeeHiveAtPosition } from '../hooks';
import { Routes } from '../types';

export function ReportsPage() {
  useAddIncidentMarkers();

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const onScrollToMap = useCallback(() => {
    mapContainerRef.current?.scroll();
    mapContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, []);

  const showBeeHivePosition = useShowBeeHiveAtPosition();
  const [searchParams] = useSearchParams();
  useLayoutEffect(() => {
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    if (lat && lng) {
      onScrollToMap();
      showBeeHivePosition({ lat: Number(lat), lng: Number(lng) });
    }
  }, [onScrollToMap, searchParams, showBeeHivePosition]);

  return (
    <Stack gap={4} py={8}>
      <Typography
        color='primary'
        fontSize={{ xs: 36, md: 48 }}
        textAlign='center'
        variant='h2'
      >
        Incidente raportate
      </Typography>

      <Typography textAlign='center' fontWeight='bold'>
        Ești victima unui caz de intoxicare a albinelor? Putem să te ajutăm,
        mergi la pagina{' '}
        <Link to={Routes.Report} style={{ textDecoration: 'none' }}>
          <Typography
            color='primary'
            component='span'
            fontWeight='bold'
            sx={{
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Raportează
          </Typography>
        </Link>
        .
      </Typography>
      <Box textAlign='center'>
        <Typography>
          Vrei să verifici incidentele raportate în locația ta?
        </Typography>
        <Button onClick={onScrollToMap} variant='contained'>
          Vezi hartă
        </Button>
      </Box>

      <Divider />

      <Typography textAlign='center' variant='h5'>
        Află coordonatele GPS ale stupinei tale
      </Typography>

      <Typography>
        Află coordonatele GPS ale stupinei tale. Mărește harta satelitară de mai
        jos până la nivelul la care recunoști fără dubiu locul unde ți-ai
        amplasat stupii. Cu puțin noroc poți să îți vezi chiar stupii la
        momentul la care a fost realizată harta. Dă click stânga pe centrul
        stupinei și vei vedea în partea de jos localitatea, județul și
        coordonatele GPS ale stupinei tale (longitudine, latitudine). Copiază
        aceste coordonate și păstrează-le pentru eventuale alte utilizări în
        viitor.
      </Typography>
      <Typography>
        Află arealul de cules al albinelor tale. Pe hartă vei vedea un cerc cu
        raza de 3 km, care este aria acoperită în mod normal în zbor de albinele
        din stupina ta.
      </Typography>

      <SearchBeeHiveLocation />

      <IncidentsMap ref={mapContainerRef} />
    </Stack>
  );
}
