import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import logoPrimorisSrc from '../assets/img/logo_primoris.png';
import logoRomapisSrc from '../assets/img/logo_romapis.png';
import { AmbulanceLink, Divider } from '../components';

export function AboutPage() {
  return (
    <Stack gap={4} py={5}>
      <Typography color='primary' fontSize={{ xs: 40, md: 52 }} variant='h2'>
        Despre proiect
      </Typography>
      <Typography>
        Proiectul APISANA a fost conceput în cadrul federației ROMAPIS în anul
        2017, a fost evaluat și aprobat de AFIR în faza de exprimare a intenției
        în 2018, a fost înaintată documentația în faza a doua de evaluare în
        2019 și s-a calificat pentru finanțare în anul 2020 iar contractul de
        finanțare a fost semnat la 31.05.2021.
      </Typography>
      <Typography>
        Obiectivul general al proiectului este realizarea unui baze de date ce
        va deriva din intocmirea unui studiu ce priveste prezenta
        contaminantilor in culturile agricole care au efect nociv asupra
        albinelor.
      </Typography>
      <Typography>
        Abordarea acestei problematici are la bază folosirea unui autovehicul
        denumit „laborator mobil” care are rolul de a pune la dispoziția
        apicultorilor echipamentele și materialele esențiale pentru recoltarea
        și conservarea corectă a eșantioanelor recoltate din câmp și din stupină
        până la momentul intrării lor în procedura de analiză de laborator
        pentru depistarea eventualilor contaminanți, în cazul unor incidente de
        intoxicare acută a albinelor. Dotarea laboratorului mobil este
        complementară utilizării acestuia de personal competent și calificat
        pentru această activitate, completată de know-how-ul dobândit la
        implementarea proiectului.
      </Typography>

      <Stack direction='column' gap={8} justifyContent='center'>
        <Box>
          <Typography fontWeight='bold' gutterBottom>
            Parteneri proiect
          </Typography>
          <Stack direction='column' gap={1}>
            <Box>
              <Link href='https://www.romapis.org/' target='_blank'>
                <img src={logoRomapisSrc} alt='ROMAPIS' />
              </Link>
            </Box>
            <Typography>- Apicultor Aga Nicolae Florin jud. Bihor</Typography>
            <Typography>
              - Apicultor Daraban Cristian Ionuț jud. Bihor
            </Typography>
            <Typography>- Apicultor Grec Cătălin-Florin jud. Bihor</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography fontWeight='bold' gutterBottom>
            Contractanți principali
          </Typography>

          <Typography>- Primoris Bulgaria</Typography>
          <Link href='https://www.primoris-lab.com/bg-en/' target='_blank'>
            <img src={logoPrimorisSrc} alt='Primoris Bulgaria' />
          </Link>

          <Box height={10} />

          <Typography>- Blankspace Data SRL</Typography>
        </Box>
      </Stack>

      <Divider />

      <Typography
        color='primary'
        fontSize={{ xs: 36, md: 48 }}
        textAlign='center'
        variant='h2'
      >
        Implementarea proiectului
      </Typography>

      <Typography>
        Proiectul a fost conceput pentru a se desfășura pe o perioadă de 36 de
        luni dar datorită perioadei scurte rămase între momentul demarării
        finanțării și sfârșitul finanțării datorată regulilor stricte ale PNDR,
        în final perioada de implementare s-a redus practic la 25 de luni
        acoperind aproximativ 3 sezoane agricole pentru testare.
      </Typography>
      <Typography>
        Rezultatele și concluziile proiectului APISANA vor fi diseminate la
        terminarea cercetării și puse la dispoziția celor interesați. Aplicația
        IT de față, dezvoltată în cadrul proiectului pune la dispoziția
        apicultorilor și a fermierilor:
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText primary='- Avertismentele fito-sanitare puse la dispoziție de autorități cu recomandările pentru folosirea pesticidelor pe care fermierii le pot folosi. Această pagină web este structurată pe județe, pentru a vă ușura informarea pentru zona în care aveți amplasați stupii.' />
        </ListItem>
        <ListItem>
          <ListItemText primary='- Raportarea de către apicultori a incidentelor cu suspiciune confirmată de intoxicare în urma tratamentelor fitosanitare. Informația este completată de o hartă structurată pe județe unde sunt evidențiate cazurile de asemenea raportări pe parcursul anului curent.' />
        </ListItem>
        <ListItem>
          <ListItemText primary='- Aflarea coordonatelor GPS ale locului de amplasare a stupilor și vizualizarea zonei de cules ale albinei în jurul respectivei locații pentru evaluarea riscului de intoxicare a albinelor dumneavoastră funcție de vecinătatea stupinei.' />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                - Posibilitatea de apelare în caz de intoxicare la „
                <AmbulanceLink />
                ”, modalitate testată în cadrul proiectului pentru intervenție
                rapidă și realizarea unor proceduri corecte de depistare a
                contaminării la care au fost supuse albinele dumneavoastră la
                suspiciunea de otrăvire.
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Stack>
  );
}
