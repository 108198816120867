import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { filter } from 'lodash';
import { fetchWarningNotices } from '../services';
import { WarningNotice } from '../types';

const defaultYear = String(new Date().getFullYear());

export function useWarningNoticesQuery(
  reportedYear?: string,
  options?: UseQueryOptions<WarningNotice[], unknown, WarningNotice[]>,
) {
  return useQuery({
    queryKey: ['warning-notices'],
    queryFn: fetchWarningNotices,
    ...options,
    select(warningNotices) {
      return filter(
        warningNotices,
        (notice) =>
          String(new Date(notice.start_date).getFullYear()) ===
            (reportedYear ?? defaultYear) ||
          String(new Date(notice.end_date).getFullYear()) ===
            (reportedYear ?? defaultYear),
      );
    },
  });
}
