import { chain } from 'lodash';
import { useCallback } from 'react';
import { PRIMARY } from '../constants';
import { useCropCategoriesQuery } from '../queries';
import { useIncidentsMap, useMap } from '../store';
import { AffectedHoneyField } from '../types';
import { buildClusterLayer } from '../utils';

// const MARKER_ZOOM = 17;

export const useBuildClusterLayer = () => {
  const { map, mapUI } = useMap();
  const { clearHiveData, setSelectedMarker } = useIncidentsMap();
  const { data: cropCategories } = useCropCategoriesQuery();

  const onNoiseMarkerTap = useCallback(
    (marker: H.map.DomMarker) => {
      if (!map) return;

      // const mapZoom = map.getZoom();
      // const markerZoom = mapZoom > MARKER_ZOOM ? mapZoom : MARKER_ZOOM;

      map.getViewModel().setLookAtData(
        {
          position: marker.getData().getPosition(),
          // zoom: markerZoom,
        },
        true,
      );

      const incident: AffectedHoneyField = marker.getData().getData();

      clearHiveData();
      setSelectedMarker(incident.pk);
    },
    [clearHiveData, map, setSelectedMarker],
  );

  const onClusterMarkerTap = useCallback(
    (marker: H.map.DomMarker, honeyFields: AffectedHoneyField[]) => {
      if (!map) return;

      map.getViewModel().setLookAtData(
        {
          position: marker.getData().getPosition(),
          // zoom: map.getZoom() + 2,
        },
        true,
      );

      const bubbleContainer = document.createElement('div');
      bubbleContainer.style.whiteSpace = 'nowrap';
      const cropsText = document.createElement('p');
      cropsText.innerText = `${chain(honeyFields)
        .groupBy('crop_category')
        .toPairs()
        .map(
          ([code, fields]) =>
            `${
              cropCategories?.find(
                (cropCategory) => cropCategory.crop_code === parseInt(code, 10),
              )?.name
            }(${fields.length})`,
        )
        .join(', ')
        .value()}`;
      cropsText.style.margin = '0';
      cropsText.style.fontFamily = 'Inter';
      cropsText.style.fontWeight = '400';
      cropsText.style.fontSize = '1rem';
      cropsText.style.lineHeight = '1.5';
      bubbleContainer.appendChild(cropsText);
      const showMore = document.createElement('button');
      showMore.innerText = 'Mărește zona';
      showMore.style.backgroundColor = 'rgba(22, 163, 74, 0.04)';
      showMore.style.outline = '0';
      showMore.style.border = '0';
      showMore.style.cursor = 'pointer';
      showMore.style.color = PRIMARY;
      showMore.style.fontFamily = 'Inter';
      showMore.style.fontWeight = '500';
      showMore.style.fontSize = '0.8125rem';
      showMore.style.lineHeight = '1.75';
      showMore.style.textTransform = 'uppercase';
      showMore.style.minWidth = '64px';
      showMore.style.padding = '4px 5px';
      showMore.style.borderRadius = '4px';
      showMore.style.marginLeft = 'auto';
      showMore.onclick = () => {
        mapUI?.getBubbles().forEach((bubble) => mapUI.removeBubble(bubble));
        map.setZoom(map.getZoom() + 2, true);
      };

      bubbleContainer.appendChild(showMore);

      mapUI?.getBubbles().forEach((bubble) => mapUI.removeBubble(bubble));
      const infoBubble = new H.ui.InfoBubble(marker.getData().getPosition(), {
        content: bubbleContainer,
      });

      mapUI?.addBubble(infoBubble);
    },
    [cropCategories, map, mapUI],
  );

  return useCallback(
    (data: AffectedHoneyField[]) => {
      if (!map) return;

      const clusterLayer = buildClusterLayer<AffectedHoneyField>(
        data,
        onClusterMarkerTap,
        onNoiseMarkerTap,
      );

      map.addLayer(clusterLayer);
    },
    [map, onClusterMarkerTap, onNoiseMarkerTap],
  );
};
