import { deburr } from 'lodash';
import { COUNTIES } from '../constants';

function toLowerCaseClean(text: string) {
  return deburr(text.toLowerCase()).replaceAll(/ș/g, 's').replaceAll(/ț/g, 't');
}

export function getCountyId(county: string) {
  return COUNTIES.find(
    ({ label }) => toLowerCaseClean(label) === toLowerCaseClean(county),
  )?.id;
}
