import { memo } from 'react';
import { MapContainer } from './MapContainer';
import { useInitializeReportIncidentMap } from '../hooks';

// eslint-disable-next-line react/display-name
export const ReportIncidentMap = memo(() => {
  const { mapRef } = useInitializeReportIncidentMap();

  return <MapContainer flexGrow={1} height={560} ref={mapRef} />;
});
