import axios from 'axios';
import { MapDiscoverResults } from '../types';

const HERE_AUTOCOMPLETE_URL = 'https://discover.search.hereapi.com/v1/geocode';

export const fetchGeocodeByAddress = async (params: {
  in?: string;
  q: string;
}): Promise<MapDiscoverResults> => {
  const queryParams = new URLSearchParams({
    ...params,
    apiKey: process.env.REACT_APP_HERE_MAPS_API_KEY ?? '',
  });
  const { data } = await axios.get(
    `${HERE_AUTOCOMPLETE_URL}?${queryParams.toString()}`,
  );

  return data;
};
