import H from '@here/maps-api-for-javascript';

export const BEES_RADIUS = 3000;
export const BEE_HIVE_KEY = 'bee-hive';
export const BEE_HIVE_MARKER_KEY = 'bee-hive-marker';
export const MAP_CENTER = { lat: 45.9432, lng: 24.9668 };
export const COUNTRY_CODE = 'countryCode:ROU';

export const DEFAULT_MAP_OPTIONS: H.Map.Options = {
  center: MAP_CENTER,
  // Romania's most extreme points https://en.wikipedia.org/wiki/List_of_extreme_points_of_Romania
  bounds: new H.geo.Rect(48.15, 20.19, 43.4, 29.4),
  pixelRatio: window.devicePixelRatio || 1,
  zoom: 0,
};

export const ROMAPIS_LOCATION = { lat: 44.4382375, lng: 26.1099853 };
export const COUNTIES_GEOJSON_URL = 'data/ro_judete_poligon.geojson';
