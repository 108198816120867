import axios from 'axios';
import { API_URLS } from '../constants';
import { WarningNotice } from '../types';

export async function fetchWarningNotices(): Promise<WarningNotice[]> {
  const { data } = await axios.get(
    `${API_URLS.WARNING_NOTICE}?ordering=start_date`,
  );

  return data;
}
