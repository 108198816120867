import {
  Box,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ambulanceSrc from '../assets/img/ambulance.jpg';
import ambulanceLogoSrc from '../assets/img/logo_ambulance.jpg';
import logoPrimorisSrc from '../assets/img/logo_primoris.png';
import { Divider } from '../components';
import { Routes } from '../types';

export function AmbulancePage() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  return (
    <Stack gap={4} py={5}>
      <Typography color='primary' fontSize={{ xs: 40, md: 52 }} variant='h2'>
        Ambulanța apicolă
      </Typography>

      <Grid container spacing={10}>
        <Grid item xs={12} md={8} order={isMobile ? 1 : 0}>
          <Typography gutterBottom textAlign='justify'>
            Prin proiectul APISANA federația ROMAPIS dispune de o furgonetă ce
            va funcționa în regim de{' '}
            <Typography color='error.main' component='span' fontWeight='bold'>
              Ambulanță apicolă
            </Typography>
            , dotată cu cele necesare pentru prelevarea corectă de probe în
            situația unui incident de intoxicare a albinelor, congelarea și
            păstrarea lor în această stare până la predarea la un laborator
            pentru analize în scopul evidențierii eventualelor contaminări
            toxice care au produs otrăvirea albinelor. Personalul care operează{' '}
            <Typography color='error.main' component='span' fontWeight='bold'>
              Ambulanța apicolă
            </Typography>{' '}
            are competența necesară pentru a vă îndruma la fața locului în așa
            fel încât procedura de anchetă a incidentului să aibă relevanță
            într-un eventual proces în instanță deschis de apicultor împotriva
            poluatorului.
          </Typography>

          <Typography gutterBottom>
            Prin proiectul APISANA avem capacitatea limitată de finanțare a până
            la 10 intervenții ale Ambulanței apicole. Scopul principal este
            testarea sistemului de alertare conceput în cadrul proiectului. Vom
            selecta dintre solicitările primite pe cele mai relevante pentru
            testarea sistemului.
          </Typography>
          <Typography gutterBottom py={4}>
            Dacă doriți să faceți o solicitare de intervenție a{' '}
            <Typography color='error.main' component='span' fontWeight='bold'>
              AMBULANȚEI APICOLE
            </Typography>{' '}
            vă rugăm să mergeți la pagina{' '}
            <RouterLink to={Routes.Report} style={{ textDecoration: 'none' }}>
              <Typography
                color='primary'
                component='span'
                sx={{ textDecoration: 'underline' }}
              >
                Raportează
              </Typography>
            </RouterLink>
            .
          </Typography>
          <Typography gutterBottom pb={3}>
            Realizarea analizelor de laborator se face prin sponsorizarea
            oferită de firma{' '}
            <Link href='https://www.primoris-lab.com/bg-en/' target='_blank'>
              <Typography color='primary' component='span'>
                Primoris
              </Typography>
            </Link>
            .
          </Typography>

          <Box sx={{ textAlign: isMobile ? 'center' : undefined }}>
            <Link href='https://www.primoris-lab.com/bg-en/' target='_blank'>
              <img src={logoPrimorisSrc} alt='Primoris Bulgaria' />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          order={isMobile ? 0 : 1}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box height={200}>
            <img
              alt='Ambulanta Apicola'
              src={ambulanceLogoSrc}
              height='100%'
              width='auto'
              style={{
                borderRadius: 'inherit',
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Box display='flex' justifyContent='center'>
        <Box borderRadius={2} boxShadow={4} maxWidth={700} width='max-content'>
          <img
            alt='Ambulanta Apicola'
            src={ambulanceSrc}
            height='100%'
            width='100%'
            style={{
              borderRadius: 'inherit',
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
