import { Button, Link, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { AlertNoticeItem } from './AlertNoticeItem';
import { DetailsPanelContainer } from './DetailsPanelContainer';
import { DetailsPanelTitle } from './DetailsPanelTitle';
import {
  useHoneyFieldNearestAddressQuery,
  useWarningNoticesQuery,
} from '../queries';
import { useIncidentsMap } from '../store';
import { getCountyId, makeLocationHref } from '../utils';

type BeeHiveDetailsPanelProps = {
  showBoxShadow?: boolean;
  showCloseButton?: boolean;
};

export function BeeHiveDetailsPanel({
  showBoxShadow,
  showCloseButton,
}: BeeHiveDetailsPanelProps) {
  const { hiveData, clearHiveData, clearSelectedMarker } = useIncidentsMap();

  const lat = hiveData?.position.lat ?? 0;
  const lng = hiveData?.position.lng ?? 0;

  const { data: nearestAddress, isLoading: isLoadingHoneyFieldNearestAddress } =
    useHoneyFieldNearestAddressQuery(
      {
        lat,
        lng,
      },
      { enabled: Boolean(hiveData?.position) },
    );

  const onCloseHandler = useCallback(() => {
    clearSelectedMarker();
    clearHiveData();
  }, [clearHiveData, clearSelectedMarker]);

  const { data: warningNotices, isLoading: isLoadingWarningNotices } =
    useWarningNoticesQuery();

  const filteredWarningNotices = useMemo(
    () =>
      warningNotices?.filter(
        (warningNotice) =>
          warningNotice.county ===
          getCountyId(nearestAddress?.address.county ?? ''),
      ) ?? [],
    [nearestAddress?.address.county, warningNotices],
  );

  const isLoading =
    isLoadingHoneyFieldNearestAddress || isLoadingWarningNotices;

  return (
    <DetailsPanelContainer
      onClose={onCloseHandler}
      showBoxShadow={showBoxShadow}
      showCloseButton={showCloseButton}
    >
      <DetailsPanelTitle
        href={makeLocationHref(
          lat,
          lng,
          `${nearestAddress?.address.street}+${nearestAddress?.address.city}`,
        )}
        isLink
        isLoading={isLoading}
        showIcon
        subtitle={
          <>
            <Typography variant='caption'>
              ~{nearestAddress?.distance}m {nearestAddress?.address.street},
              {nearestAddress?.address.city},
            </Typography>
            <Typography textTransform='uppercase' variant='caption'>
              {nearestAddress?.address.county}
            </Typography>
          </>
        }
        title={`${lat.toPrecision(6)}, ${lng.toPrecision(6)}`}
      />

      <Typography fontWeight={700}>Incidente raportate</Typography>

      {!hiveData?.honeyFieldsInRange.length && (
        <Typography>
          Nu există incidente raportate pe raza de acțiune a albinelor pentru
          locația selectată
        </Typography>
      )}

      {(hiveData?.honeyFieldsInRange.length ?? 0) > 0 && (
        <Typography>
          Incidente raportate: {hiveData?.honeyFieldsInRange.length}
        </Typography>
      )}

      <Typography fontWeight={700}>Buletine de avertizare</Typography>

      {!filteredWarningNotices.length && (
        <Typography>
          Nu există buletine de avertizare în județul pentru locația selectată
        </Typography>
      )}

      {(filteredWarningNotices.length ?? 0) > 0 &&
        filteredWarningNotices.map((warningNotice) => (
          <AlertNoticeItem key={warningNotice.pk} alert={warningNotice} />
        ))}

      <Stack
        alignItems='center'
        bgcolor='white'
        display='flex'
        direction='row'
        position='sticky'
        bottom={-8}
        py={2}
        gap={1}
      >
        <Button
          href={`http://agroapis.polenizare.ro/?lat=${lat}&lng=${lng}`}
          LinkComponent={Link}
          target='_blank'
          variant='outlined'
          fullWidth
        >
          Vezi culturile din zonă
        </Button>
      </Stack>
    </DetailsPanelContainer>
  );
}

BeeHiveDetailsPanel.defaultProps = {
  showBoxShadow: false,
  showCloseButton: false,
};
