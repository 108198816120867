import { Stack } from '@mui/material';
import { forwardRef } from 'react';
import { IncidentsMapSidePanel } from './IncidentsMapSidePanel';
import { Map } from './Map';

// eslint-disable-next-line react/display-name
export const IncidentsMap = forwardRef((_, ref) => (
  <Stack ref={ref} direction='row' gap={3} position='relative'>
    <Map height={560} />

    <IncidentsMapSidePanel />
  </Stack>
));
