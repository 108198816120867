import axios from 'axios';
import { API_URLS } from '../constants';
import { ReportForm } from '../types';

export const reportIncident = async (
  reportBody: Omit<
    ReportForm,
    'accepted_terms' | 'incident_location' | 'county'
  >,
): Promise<{ pk: string }> => {
  const { data } = await axios.post(API_URLS.ALERTS, reportBody);

  return data;
};
