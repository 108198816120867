export enum Routes {
  AboutProject = '/despre',
  Reports = '/incidente-raportate',
  // Alerts = '/alerte',
  Contact = '/contact',
  Home = '/',
  // Policy = '/politica-confidentialitate',
  Report = '/raporteaza',
  Terms = '/termeni-conditii',
  InstallInstructions = '/instructiuni-instalare',
}

type RouteConfig = {
  label: string;
  route: Routes;
  isFooterLink?: boolean;
};

export type RoutesConfig = RouteConfig[];
