const SITE_URL = process.env.REACT_APP_DJANGO_SITE_URL;
const PORT = process.env.REACT_APP_DJANGO_PORT;
const API_ENDPOINT = process.env.REACT_APP_DJANGO_API_ENDPOINT;
const BASE_URL = `${SITE_URL}:${PORT}/${API_ENDPOINT}`;

export const API_URLS = {
  ALERTS: `${BASE_URL}/incident/`,
  ALERT_DOCUMENTS: `${BASE_URL}/incident_document/`,
  CONTACT: `${BASE_URL}/contact/`,
  CROP_CATEGORIES: `${BASE_URL}/crop_categories/`,
  HONEY_FIELD: `${BASE_URL}/honey_field/`,
  PHYTOSANITARY_CHOICES: `${BASE_URL}/phytosanitary_treatments/`,
  VANDALISM_CHOICES: `${BASE_URL}/vandalism_choices/`,
  WARNING_NOTICE: `${BASE_URL}/warning_notice/`,
};
