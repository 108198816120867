import H from '@here/maps-api-for-javascript';
import { useCallback } from 'react';
import { useRemoveMapObjectsByKey } from './useRemoveMapObjectsByKey';
import { BEE_HIVE_KEY } from '../constants';
import { useIncidentsMap, useMap } from '../store';
import { createBeeHiveAtPosition, getMapObjectsInBoundingBox } from '../utils';

export const useShowBeeHiveAtPosition = () => {
  const { map } = useMap();
  const { clearSelectedMarker, setHiveData } = useIncidentsMap();
  const removeMapObjectsByKey = useRemoveMapObjectsByKey();

  return useCallback(
    (position: H.geo.IPoint) => {
      clearSelectedMarker();
      removeMapObjectsByKey(BEE_HIVE_KEY, map);

      const beeHive = createBeeHiveAtPosition(position);

      map?.addObject(beeHive);
      map?.getViewModel().setLookAtData({
        bounds: beeHive.getBoundingBox(),
      });

      if (map) {
        const markers = getMapObjectsInBoundingBox(
          map,
          beeHive.getBoundingBox(),
        );

        const honeyFields = markers.map((m) => m.getData().getData());

        setHiveData(position, honeyFields);
      }
    },
    [clearSelectedMarker, map, removeMapObjectsByKey, setHiveData],
  );
};
