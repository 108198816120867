import H from '@here/maps-api-for-javascript';
import { omit } from 'lodash';
import { StateCreator } from 'zustand';
import { ReportIncidentSlice } from './types';

export const createReportIncidentSlice: StateCreator<ReportIncidentSlice> = (
  set,
) => ({
  clearIncidentLocation: () => set((state) => omit(state, 'incidentLocation')),
  setIncidentLocation: (position: H.geo.IPoint) =>
    set({ incidentLocation: position }),
});
