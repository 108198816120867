import H from '@here/maps-api-for-javascript';
import { useCallback, useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { BEE_HIVE_KEY, DEFAULT_MAP_OPTIONS } from '../constants';
import { useMap, useIncidentsMap } from '../store';
import {
  addDragMarkerHandlers,
  createBeeHiveAtPosition,
  getMapObjectsInBoundingBox,
} from '../utils';

export function useInitializeHomeMap(
  {
    disableDefaultEvents,
    enableDragMarkerEvents,
    onDragEnd,
  }: {
    disableDefaultEvents?: boolean;
    enableDragMarkerEvents?: boolean;
    onDragEnd?: (point: H.geo.Point) => void;
  } = { disableDefaultEvents: false },
) {
  const mapRef = useRef<HTMLElement>();
  const { map: newMap, disposeMap, setMap } = useMap();
  const { clearSelectedMarker, setHiveData } = useIncidentsMap();

  const onResize = useCallback(() => {
    newMap?.getViewPort().resize();
  }, [newMap]);

  useResizeDetector({
    onResize,
    skipOnMount: true,
    targetRef: mapRef,
  });

  useEffect(() => {
    if (!process.env.REACT_APP_HERE_MAPS_API_KEY || !mapRef.current) {
      return undefined;
    }

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY,
    });

    const layers = platform.createDefaultLayers();

    const map = new H.Map(mapRef.current, layers.raster.satellite.map, {
      ...DEFAULT_MAP_OPTIONS,
    });

    const events = new H.mapevents.MapEvents(map);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const behavior = new H.mapevents.Behavior(events);

    const ui = H.ui.UI.createDefault(map, layers);

    setMap(map, behavior, ui);

    if (!disableDefaultEvents) {
      map.addEventListener('tap', (event: H.mapevents.Event) => {
        const coord = map.screenToGeo(
          event.currentPointer.viewportX,
          event.currentPointer.viewportY,
        );

        if (coord) {
          clearSelectedMarker();
          const mapObjects = map.getObjects();
          const filteredObjects =
            mapObjects?.filter(
              (object) => object.getData()?.key === BEE_HIVE_KEY,
            ) ?? [];

          map.removeObjects(filteredObjects);

          const beeHive = createBeeHiveAtPosition(coord);

          map.addObject(beeHive);
          map.getViewModel().setLookAtData(
            {
              bounds: beeHive.getBoundingBox(),
            },
            true,
          );

          const markers = getMapObjectsInBoundingBox(
            map,
            beeHive.getBoundingBox(),
          );

          setHiveData(
            coord,
            markers.map((o) => o.getData().getData()),
          );
        }
      });
    }

    if (enableDragMarkerEvents) {
      addDragMarkerHandlers(map, behavior, onDragEnd);
    }

    return () => {
      map.dispose();
      disposeMap();
    };
  }, [
    clearSelectedMarker,
    disableDefaultEvents,
    disposeMap,
    enableDragMarkerEvents,
    onDragEnd,
    setHiveData,
    setMap,
  ]);

  return mapRef;
}
