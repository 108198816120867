import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchCropCategories } from '../services';
import { CropCategory } from '../types';

export function useCropCategoriesQuery(
  options?: UseQueryOptions<
    CropCategory[] | undefined,
    Error,
    CropCategory[] | undefined,
    string[]
  >,
) {
  const enabled = options?.enabled ?? true;

  return useQuery(['crop-categories'], fetchCropCategories, {
    ...options,
    enabled,
  });
}
