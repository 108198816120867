import { useCallback } from 'react';
import { useRemoveMapObjectsByKey } from './useRemoveMapObjectsByKey';
import { BEES_RADIUS } from '../constants';
import { useReportMap } from '../store';
import {
  createCircleAtPosition,
  createDomMarkerAtPosition,
  INCIDENT_ICON,
  INCIDENT_LOCATION_KEY,
} from '../utils';

export const useAddIncidentMarkerAtLocation = () => {
  const { map } = useReportMap();
  const removeMapObjectsByKey = useRemoveMapObjectsByKey();

  return useCallback(
    (position: H.geo.IPoint) => {
      removeMapObjectsByKey(INCIDENT_LOCATION_KEY, map);

      const marker = createDomMarkerAtPosition(position, {
        icon: INCIDENT_ICON,
        data: { key: INCIDENT_LOCATION_KEY },
      });

      const circle = createCircleAtPosition(position, BEES_RADIUS);

      const group = new H.map.Group({
        objects: [marker, circle],
        data: { key: INCIDENT_LOCATION_KEY },
      });
      group.draggable = true;

      map?.addObject(group);
      map?.getViewModel().setLookAtData(
        {
          bounds: group.getBoundingBox(),
        },
        true,
      );
    },
    [map, removeMapObjectsByKey],
  );
};
