import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import Link from '@mui/material/Link/Link';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ContactForm, Divider } from '../components';
import { ROMAPIS_LOCATION } from '../constants';
import { useInitializeContactMap } from '../hooks/useInitializeContactMap';
import { isAppleProduct } from '../utils';

export function ContactPage() {
  const { typography } = useTheme();
  const { mapRef } = useInitializeContactMap();

  return (
    <Stack gap={4} py={8}>
      <Typography
        color='primary'
        fontSize={{ xs: 36, md: 48 }}
        textAlign='center'
        variant='h2'
      >
        Contact
      </Typography>

      <Divider />

      <Grid columnSpacing={8} container rowSpacing={20}>
        <Grid item xs={12}>
          <Typography
            color='primary'
            fontSize={36}
            fontWeight={typography.fontWeightBold}
            mb={6}
          >
            Date de contact ROMAPIS
          </Typography>

          <Stack columnGap={12} direction='row' flexWrap='wrap' rowGap={6}>
            <Box>
              <Typography>Adresă de email</Typography>
              <Typography fontWeight={typography.fontWeightBold}>
                office.romapis@gmail.com
              </Typography>
            </Box>

            <Box>
              <Typography>Coordonator proiect</Typography>
              <Typography fontWeight={typography.fontWeightBold}>
                Constantin Dobrescu
              </Typography>
            </Box>

            <Box>
              <Typography>Telefon</Typography>
              <Typography fontWeight={typography.fontWeightBold}>
                0744823628
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs>
          <Typography
            color='primary'
            fontSize={36}
            fontWeight={typography.fontWeightBold}
          >
            Ai o întrebare despre proiect?
          </Typography>
          <Typography mb={4} variant='body2'>
            <strong>Notă:</strong> Folosește acest formular pentru a afla mai
            multe informații despre proiect sau pentru a semnala o problemă
            întâlnită în această aplicație.
          </Typography>

          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_CAPTCHA_API_KEY ?? ''}
            language='ro'
          >
            <ContactForm />
          </GoogleReCaptchaProvider>
        </Grid>

        <Grid item xs>
          <Stack height={1} gap={3}>
            <Typography
              color='primary'
              fontSize={36}
              fontWeight={typography.fontWeightBold}
            >
              Sediu implementare proiecte
            </Typography>

            <Typography
              color='text.primary'
              component={Link}
              href={
                isAppleProduct()
                  ? `http://maps.apple.com/?q=Romapis&ll=${ROMAPIS_LOCATION.lat},${ROMAPIS_LOCATION.lng}`
                  : `https://www.google.com/maps/search/?api=1&query=${ROMAPIS_LOCATION.lat}%2C${ROMAPIS_LOCATION.lng}&query_place_id=ChIJJakXENf_sUARQLym_2SYQ74`
              }
              fontWeight={typography.fontWeightBold}
              target='_blank'
              underline='hover'
            >
              Str. Arcului, nr. 26, et. 3, ap. 8, Sector 2, București
            </Typography>

            <Box borderRadius={2} boxShadow={5} flexGrow={1} ref={mapRef} />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
